/* eslint-disable */
import React, { createContext, useContext, useEffect, useState } from "react";
import { getEmployeeInfoAndReferenceData, validateApprover } from "../../shared/getEmployeeInfoAndReferenceData";
import { ContainerContext } from "../../../app/App";
import { CustomPropInterface } from "../../..";
import { secondaryCriteriaDetails } from "../../../api/user";
import moment from "moment";

export const CriteriaContext = createContext<any>("");
export function CriteriaContextProvider(props: any) {
    const referenceType = ['RULE_APPLICATION_TYPE', 'SECONDARY_CRITERIA_TYPE', 'CATEGORY_TYPE', 'CATEGORY_SUBTYPE', 'EQUATION_TYPE', 'PRICING_PARAM_KEY', "DEPARTMENT", "SUBDEPARTMENT", "BRACKET"]
    const containerData = useContext(ContainerContext) as CustomPropInterface;


    const [dropdownValues, setDropDownValues] = useState<any>({
        department: [],
        subdepartment: [],
        bracket: [],
        zone: [],
        ruleType: [],
        secondaryCriteria: [],
        pricingParams: [],
        equationType: [],
        categoryType: [],
        categorySubType: []
    });
    const [editRecordsArray, setEditRecordsArray] = useState<any[]>([]);
    const [handlePopup, setHandlePopup] = useState<any>({
        isOpen: false,
        Content: '',
        index: 0,
        type : ''
    })
    const [showComp, setShowComp] = useState({
        form: false,
        filter: true,
        grid: false
    })
    const [userInfo, setUserInfo] = useState<any>({
        emailId: '',
        employeeId: '',
        IsFranchiseeUser: false,
        isApprover: false,
        CompanyCode: []

    })
    const [loader, setLoader] = useState({
        dropDownLoader: false,
        mainPage: false,
        grid: false
    })
    const [searchData, setSearchData] = useState<any>({
        department: '',
        subdepartment: '',
        bracket: '',
        zone: '',
        ruleType: '',
        secondaryCriteriaType: '',
        priceParameter: '',
        brand: '',
        rms: ''
    })
    const gridHeadersObj = [
        {
            heading: "Zone",
            isSortable: true,
            sortDirection: "DESC",
            key: "zoneNumber",
            isSorted : true
        },
        {
            heading: "Department",
            isSortable: true,
            sortDirection: "DESC",
            key: "departmentName",
            isSorted : true
        },

        {
            heading: 'Sub-Department',
            isSortable: true,
            sortDirection: "DESC",
            key: "subdepartmentName",
            isSorted : true
        },
        {
            heading: 'Bracket',
            isSortable: false,
            sortDirection: "DESC",
            key: "bracketName",
            isSorted : false
        },
        {
            heading: 'Brand',
            isSortable: false,
            sortDirection: "DESC",
            key: "brandName",
            isSorted : false
        },
        {
            heading: 'RMS #',
            isSortable: true,
            sortDirection: "DESC",
            key: "rmsItemNumber",
            isSorted : true
        },
        {
            heading: 'Rule Type',
            isSortable: true,
            sortDirection: "DESC",
            key: "ruleApplicationType",
            isSorted : true
        },
        {
            heading: 'Secondary Criteria Type',
            isSortable: true,
            sortDirection: "DESC",
            key: "secondaryCriteriaType",
            isSorted : true
        },
        {
            heading: 'Price Parameter',
            isSortable: true,
            sortDirection: "DESC",
            key: "priceParameter",
            isSorted : true
        },
        {   
            heading: 'Start Date',
            isSortable: true,
            sortDirection: "DESC",
            key: "startDate",
            isSorted : true
        },
        {
            heading: 'End Date',
            isSortable: true,
            sortDirection: "DESC",
            key: "endDate",
            isSorted : true
        },
        {
            heading: 'Action',
            isSortable: true,
            sortDirection: "DESC",
            key: "action"
        },
    ]
    const [gridHeaders, setGridHeaders] = useState(gridHeadersObj);
    const [searchFilterResponse, setSearchFilterResponse] = useState([])
    const [secondaryCriteriaSearchContent, setSecondaryCriteriaSearchContent] = useState<any>([])
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const criteriaDetailsObject = {
        "zone": [],
        "departmentNumber": '',
        "subdepartmentNumber": '',
        "bracketNumber": '',
        "rmsItemNumber": '',
        "brandName": '',
        "ruleType": 'CATEGORY',
        "secondaryCriteriaType": 'CATEGORY',
        "pricingParameterKey": '',
        "effectType": '',
        "applicationOrder": '',
        "startDate": '',
        "endDate": moment('2099-12-31').format('YYYY-MM-DD'),
        "category": {
            categoryType: 'DEPRECTYP',
            categorySubType: '',
            discountValue: ''
        },
        "range": null,
        "equation": null
    }
    const [selectQueueType, setSelectQueueType] = useState('');
    const [editCriteria, setEditCriteria] = useState(false)
    const [criteriaDetailsForm, setCriteriaDetailsForm] = useState<any>(criteriaDetailsObject);
    const [zoneSelectedForm, setZoneSelectedForm] = useState([]);
    const [backupRecords, setBackupRecords] = useState<any>({});
    

    function dropDownSetter(data: any[], type: string, isSeelectNeed: Boolean = true) {
        const dataArray: any = [];
        if (type == 'zone') {
            data.map((item: any) => {
                dataArray.push({
                    label: item?.label,
                    value: (item?.value)?.toString(),
                })
            })
        } else if (type == 'department') {
            data.map((item: any) => {
                dataArray.push({
                    label: item?.descEn,
                    value: (item?.departmentNumber)?.toString(),
                })
            })
        } else if (type == 'ruleType') {
            data.map((item: any) => {
                dataArray.push({
                    label: item?.refCode,
                    value: (item?.refCode)?.toString(),
                })
            })
        }
        else if (type == 'secondaryCriteriaType') {
            data.map((item: any) => {
                dataArray.push({
                    label: item?.secondaryCriteriaTypeName,
                    value: (item?.refCode)?.toString(),
                })
            })
        }
        else if (type == 'priceParameter') {
            data.map((item: any) => {
                dataArray.push({
                    label: item?.pricingParamKeyName,
                    value: (item?.refCode)?.toString(),
                })
            })
        } else if (type == 'categoryType') {
            data.map((item: any) => {
                dataArray.push({
                    label: item?.categoryTypeName,
                    value: (item?.refCode)?.toString(),
                })
            })
        }
        else if (type == 'categorySubType') {
            data.map((item: any) => {
                dataArray.push({
                    label: item?.categorySubTypeName,
                    value: (item?.refCode)?.toString(),
                    categoryType: item?.categoryTypeName
                })
            })
        }
        else if (type == 'equationType') {
            data.map((item: any) => {
                dataArray.push({
                    label: item?.equationTypeName,
                    value: (item?.refCode)?.toString(),
                    params: item.params
                })
            })
        }
        if(type == 'zone'){
            dataArray.sort((a: any, b: any) =>a["label"].localeCompare(b["label"], undefined, { numeric: 'true' }))
        }else
            dataArray.sort((a: any, b: any) => a.label?.localeCompare(b.label))
        if (type != 'zone' && isSeelectNeed) {
            dataArray.unshift({ label: 'Select', value: '' });
        }
        return dataArray
    }

    function handleCancelClick() {
        setShowComp({ ...showComp, form: false })
        setEditCriteria(false);
        setSelectQueueType('');
        setZoneSelectedForm([]);
        setEditRecordsArray([]);
        setCriteriaDetailsForm(criteriaDetailsObject);
    }

    useEffect(() => {
        getAccessAndDropdown();

    }, []);
    const getAccessAndDropdown = () => {

        const req = {
            type: referenceType,
            isSecondaryCriteria : true
        }
        setLoader({ ...loader, dropDownLoader: true, mainPage: false, grid :false });
        getEmployeeInfoAndReferenceData(containerData, req).then((rep) => {

            const { employeeId, zone, dropdownData, userName, emailId, companyCode, isFranchiseeUser, menuConfigArray, status } = rep;
            const approver = validateApprover(menuConfigArray);
            let companyCodes: any = []
            if (isFranchiseeUser) {
                if (companyCode?.length > 0)
                    companyCodes = companyCode
                else
                    setHandlePopup({ isOpen: true, Content: 'Something went wrong', index: 0, type :'error' });
            }
            setUserInfo({
                ...userInfo,
                employeeId: employeeId,
                emailId: emailId,
                isApprover: approver,
                IsFranchiseeUser: isFranchiseeUser,
                CompanyCode: companyCodes


            });
            setLoader({ ...loader, dropDownLoader: false, mainPage: false });
            const { bracket, categorySubType, categoryType, department, equationType, pricingParamKey, ruleApplicationType, secondaryCriteriaType, subdepartment } = dropdownData;
            if (status == 200) {
                setDropDownValues({
                    ...dropdownValues,
                    department: department,
                    subdepartment: subdepartment,
                    bracket: bracket,
                    zone: zone,
                    ruleType: ruleApplicationType,
                    secondaryCriteria: secondaryCriteriaType,
                    pricingParams: pricingParamKey,
                    equationType: equationType,
                    categoryType: categoryType,
                    categorySubType: categorySubType,
                })
            }
            sessionStorage.setItem("EmployeeId", employeeId);
            sessionStorage.setItem("userName", userName ? rep.userName : "");
        }).catch((err) => {
            console.error(err, "the error  in  useeffect");
            setHandlePopup({ isOpen: true, Content: 'Something went wrong',  index: 0, type :'error' });
        });
    }

    const searchSecondaryCriteriaDetails = async (offset: any = '1') => {
        try {
            setLoader({ ...loader, mainPage: false, grid : true });
            setShowComp({ ...showComp, grid: true });
            const request = {
                zoneNumber: searchData.zone,
                departmentNumber: searchData.department,
                subdepartmentNumber: searchData.subdepartment,
                bracketNumber: searchData.bracket,
                brandName: searchData.brand,
                rmsItemNumber: searchData.rms,
                ruleType: searchData.ruleType,
                secondaryCriteriaRefCode: searchData.secondaryCriteria,
                priceParameter: searchData.priceParameter,
                companyCode: userInfo.companyCode?.length > 0 ? userInfo.companyCode : undefined,
                isPricingFlow: 'Y',
                offset: offset,
                limit: '25',
            }
            console.log(request, 'request in search fn')
            const searchResponse = await secondaryCriteriaDetails(request);
            if (searchResponse.status == 200) {
                setShowComp({ ...showComp, filter: false, grid: true });
                setTotalRowCount(searchResponse.data.totalRecords)
                setSecondaryCriteriaSearchContent(searchResponse.data.result);
                setPageCount(Math.ceil(searchResponse.data.totalRecords / 25));
            }
            else if (searchResponse.status == 400) {
                setShowComp({ ...showComp, filter: true, grid: false });
                const content = searchResponse.data?.errors[0]?.error.trim()?.length > 0 ? searchResponse.data?.errors[0]?.error.trim() : 'Something went wrong'
                setHandlePopup({ isOpen: true, Content: content, index: 0, type :'error' });
            }
            else {
                setShowComp({ ...showComp, filter: true, grid: false });
                setHandlePopup({ isOpen: true, Content: 'Something went wrong' , index: 0, type :'error'});
            }

        }
        catch (e) {
            setShowComp({ ...showComp, filter: true, grid: false });
            setHandlePopup({ isOpen: true, Content: 'Something went wrong', index: 0, type :'error' });
        }
        finally {
            setLoader({ ...loader, mainPage: false,  grid : false });
        }
    }
    const handleDeleteRow = (rowIndex: any) => {
        setCriteriaDetailsForm({
          ...criteriaDetailsForm,
          ["range"]: criteriaDetailsForm.range.filter(
            (_: any, index: any) => index !== rowIndex
          ),
        });
        setBackupRecords({
            ...backupRecords,
            range: backupRecords?.range?.filter(
              (_: any, index: any) => index !== rowIndex
            ),
          });
  
        let editArr = editRecordsArray;
        editArr.forEach((item: any, index: any) => {
          console.log(item, "edit the issue");
    
          editArr[index] = item < rowIndex ? editArr[index] : editArr[index] - 1;
        });
        setEditRecordsArray([...editArr]);
        setHandlePopup({
          isOpen: false,
          index: undefined,
          type: 'error',
          Content : ''

        });
      };

  const sortArray = (
    columnId: string,
    index: number,
    datacolumn: any, 
    dataValues: any ,
    isDate: boolean = false,
    compareBy : string = ''
  ) => {
    let sortedData;
    debugger;
    if(compareBy){
        sortedData = dataValues.sort((a: any, b: any) => {
            // Handle null values
            if (a[columnId] === null && b[columnId] === null) return 0; // Both are null
            if (a[columnId] === null) return 1; // Nulls should come last
            if (b[columnId] === null) return -1; // Non-nulls should come first
    
            // Compare strings using localeCompare
            const comparison = a[columnId].localeCompare(b[columnId]);
            
            // Return based on the specified order
            return datacolumn[index].sortDirection == "DESC" ? -comparison : comparison;
        });
    }
   else if(isDate){
        sortedData =
      datacolumn[index].sortDirection == "DESC"
        ? dataValues.sort((a: any, b: any) =>
           {
            let date1 = moment(b[columnId]);
            let date2 = moment(a[columnId]);
            return date2.isBefore(date1) ? -1 : 1;
           }
          )
        : dataValues.sort((a: any, b: any) =>{
          let date1 = moment(b[columnId]);
          let date2 = moment(a[columnId]);
          return date1.isBefore(date2) ? -1 : 1;
        });
   }else{ 
    debugger;
    sortedData  =
      datacolumn[index].sortDirection == "DESC"
        ? dataValues.sort((a: any, b: any) =>{
            console.log('The sort array', b[columnId] , a[columnId],b[columnId] < a[columnId] ? -1 : 1 )
            return b[columnId] < a[columnId] ? -1 : 1
          })
        : dataValues.sort((a: any, b: any) =>
            a[columnId] < b[columnId] ? -1 : 1
          );
   }
    const reorderColumns: any = datacolumn;
    reorderColumns[index].sortDirection =
      reorderColumns[index].sortDirection == "ASC" ? "DESC" : "ASC";


    setSecondaryCriteriaSearchContent([...sortedData]);
    setGridHeaders([...reorderColumns]);

  };


  function createDiscountIntervals() {
    const result = [];
    const intervals = [30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 
        330, 360, 390, 420, 450, 480, 510, 540, 
        570, 600, 630, 660, 690, 720, 750, 
        780, 810, 840, 870];
    
    // Initialize the start range
    let currentStart = 0;

    // Loop through the intervals to create the array of objects
    for (const end of intervals) {
        result.push({
            startRange: currentStart.toString(),
            endRange: end.toString(),
            discountValue: ""
        });
        // Update the start range for the next interval
        currentStart = end + 1;  // The next start should be one more than the current end
    }

    // Add the last interval with endDay as 9999
    result.push({
        startRange: currentStart.toString(),
        endRange: "9999",
        discountValue: ""
    });

    return result;
}

    return (
        <CriteriaContext.Provider value={{
            dropdownValues,
            setDropDownValues,
            handlePopup,
            setHandlePopup, userInfo, setUserInfo,
            loader, setLoader,
            searchData, setSearchData,
            searchFilterResponse, setSearchFilterResponse,
            gridHeaders, secondaryCriteriaSearchContent, setSecondaryCriteriaSearchContent,
            totalRowCount, setTotalRowCount,
            pageNumber, setPageNumber,
            pageCount, setPageCount,
            searchSecondaryCriteriaDetails,
            criteriaDetailsForm, setCriteriaDetailsForm,
            dropDownSetter, sortArray,
            selectQueueType, setSelectQueueType,
            criteriaDetailsObject,
            editCriteria, setEditCriteria,
            handleCancelClick,
            showComp, setShowComp,
            createDiscountIntervals,
            editRecordsArray, setEditRecordsArray,
            handleDeleteRow,
            zoneSelectedForm, setZoneSelectedForm,
            backupRecords, setBackupRecords
        }}>
            {props.children}
        </CriteriaContext.Provider>
    )
}
