/* eslint-disable */
import { RACCOLOR, makeStyles } from "@rentacenter/racstrap";
export const SecondaryCriteriaStyle = () => {
    const useClasses = makeStyles((theme) => ({
        floatLeft: {
            float: 'left',
        },
        floatRight: {
            float: 'right',
        },
        pagePadding: {
            padding: "0 10px 10px 10px",
            paddingTop: theme.typography.pxToRem(24),
            width: '100%'
        },
        title1: {
            color: '#000',
            fontFamily: 'OpenSans-bold',
            fontWeight: "bold",
            fontSize: '15px',
        },
        card: {
            borderRadius: theme.typography.pxToRem(15),
            boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
            width: '100%',
            height: 'auto'
        },
        mb15: {
            marginBottom: '15px',
        },
        mr10: {
            marginRight: '10px',
        },
        ml2: {
            marginLeft: theme.typography.pxToRem(8),
        },
        prcingzoneMultiSelect: {
            "& .dropdown-container": {
                "& div": {
                    overflow: "hidden !important",
                },
                height: "31px",
            },
            "& .dropdown-heading": {
                height: "31px !important",
            },
            "& .dropdown-content": {
                borderRadius: "8px",
                boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)!important",
                zIndex: "99",
            },
        },
        demo: {
            "& .dropdown-content": {
                "& .search": {
                    border: "1px solid #e3e3e3",
                },
            },
            "& .options": {
                overflowX: "hidden !important",
            },
        },
        gridStyle: {
            "& thead": {
                position: "sticky",
                top: "0",
                zIndex: "1",
                backgroundColor: "#FFF",
            },
            "& tr": {
                "& th": {
                    padding: "12px 20px !important",
                    backgroundColor: "#ffffff",
                    fontFamily: "OpenSans-bold",
                    fontSize: "0.8rem",
                },
                "& td": {
                    padding: "12px 20px !important",
                    fontFamily: "OpenSans-semibold",
                    color: "#3c454f",
                    fontSize: "0.8rem",
                },
                "& th:last-child": {
                    position: "sticky",
                    right: "0",
                    backgroundColor: "#FFF",
                    paddingLeft: '0px !important',
                    paddingRight: '0px !important',
                    display: 'flex',
                    alignItems: 'center',
                    width: '42px',
                    justifyContent: 'flex-end',
                    minHeight: '40px !important'
                },
                "& td:last-child": {
                    position: "sticky",
                    right: "0",
                    backgroundColor: "#FFF",
                    paddingLeft: '0px !important',
                    paddingRight: '0px !important',
                    display: 'flex',
                    width: '42px',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                },
            },
        },
        textCenter: {
            textAlign: "center",
        },
        textRight: {
            textAlign: "right",
        },
        textLeft: {
            textAlign: "left",
        },
        pagination: {
            display: "flex",
            listStyle: "none",
            textDecoration: "none",
            fontFamily: "OpenSans-semibold",
            fontSize: theme.typography.pxToRem(14),
            justifyContent: "flex-end",
            cursor: "pointer",
        },
        paginationprevnext: {
            color: "#000",
            padding: "5px 17px",
            display: "inline-block",
        },
        paginationprevnextdisable: {
            color: "lightgray !important",
            padding: "5px 17px",
            display: "inline-block",
        },
        pageactive: {
            backgroundColor: "#2468FF",
            color: "#fff",
            borderRadius: "50%",
        },
        pagelink: {
            position: "relative",
            display: "block",
            textDecoration: "none",
            padding: "5px 0px",
            width: "29px",
            textAlign: "center",
        },
        centerAlignImage: {
            textAlign: "center",
            marginTop: theme.typography.pxToRem(20),
            width: "100%",
            fontFamily: "OpenSans-bold",
            fontSize: theme.typography.pxToRem(16),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        masterLoader: {
            position: "fixed",
            backgroundColor: `${RACCOLOR.WASHED_BLACK}`,
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            opacity: 0.6,
            zIndex: 1000,
            textAlign: "center",
            margin: 0,
          },
          Loader: {
            display: "block",
            position: "fixed",
            zIndex: 1051,
            top: "50%",
            right: "50%",
          },
          businessValue: {
            zIndex: 3,
            '& input':{
                borderTopLeftRadius:'0px !important',
                borderBottomLeftRadius:'0px !important',
                textOverflow:'ellipsis'
            },
        }
    }));
    const classes = useClasses();
    return classes;
};
