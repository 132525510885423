/* eslint-disable */
import React, { createContext, useState, useEffect, useContext } from "react";

import { fetchPricingQueueAPI } from "../../../api/user";
import { getEmployeeInfoAndReferenceData, validateApprover } from "../../shared/getEmployeeInfoAndReferenceData";
import { ContainerContext } from "../../../app/App";
import { CustomPropInterface } from "../../..";
import { transformString } from "../../../utils/utils";
export interface filterRequest {
  type?: string;
  status?: string;
  schedule?: string;
  fromDate?: string;
  toDate?: string;
  limit?: string;
  offset?: string;
  companyCode?: string[];
}
export const PricingQueueContext = createContext<any>("");
export default function PQContext(props: any) {
  const [isAlterOpen,setIsAlterOpen] = useState(false);

  const [autotermRange,setAutotermRange] = useState<any>([]);

  const sortDirection = {
    ASC: "asc",
    DESC: "desc",
  };
  const [hideMainPage,setHideMainPage] = useState(false);
  const [tableHeader, setTableHeader] = useState([
    {
      columnTitle: "Type",
      sortOrder: sortDirection.ASC,
      columnId: "type",
      IsSorted: true,
      IsSortable: false,
    },
    {
      columnTitle: "Schedule",
      sortOrder: sortDirection.ASC,
      columnId: "schedule",
      IsSorted: true,
      IsSortable: true,
    },
    {
      columnTitle: "Submitted Date & Time",
      sortOrder: sortDirection.ASC,
      columnId: "submittedDateTime",
      IsSorted: true,
      IsSortable: true,
    },
    {
      columnTitle: "Submitted by",
      sortOrder: sortDirection.ASC,
      columnId: "submittedBy",
      IsSorted: true,
      IsSortable: false,
    },
    {
      columnTitle: "Approved by",
      sortOrder: sortDirection.ASC,
      columnId: "approvedBy",
      IsSorted: true,
      IsSortable: false,
    },
    {
      columnTitle: "Comments",
      sortOrder: sortDirection.ASC,
      columnId: "comments",
      IsSorted: true,
      IsSortable: false,
    },
    {
      columnTitle: "Status",
      sortOrder: sortDirection.ASC,
      columnId: "status",
      IsSorted: true,
      IsSortable: false,
    },
    {
      columnTitle: "Progress",
      sortOrder: sortDirection.ASC,
      columnId: "progress",
      IsSorted: true,
      IsSortable: false,
    },
    {
      columnTitle: "Action",
      sortOrder: sortDirection.ASC,
      columnId: "action",
      IsSorted: true,
      IsSortable: false,
    },
  ]);
  const [comments, setComments] = useState<any>("");
  const [companyCode, setCompanyCode] = useState([]);
  const [isFranchiseeUser, setIsFranchiseeUser] = useState(false);
  const [noRecords, setNoRecords] = useState(false);
  const [tableBody, setTableBody] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [approvalPopup, setApprovalPopup] = useState("");
  const [zoneDetails, setZoneDetails] = useState(false);
  const [loader, setLoader] = useState<any>('Y');
  const [alterPopUp, setAlterPopUp] = useState<any>();
  const [isApproved, setIsApproved] = useState(false);
  const [IsPackage, setIsPackage ] = useState(false);
  let rangeHeaders = [{
    heading: "Days on Rent Start",
    isSortable: true,
    sortDirection: "DESC",
    key: "startDay",
    isSorted: true,
  },
  {
    heading: "Days on Rent End",
    isSortable: true,
    sortDirection: "DESC",
    key: "endDay",
    isSorted: true,
  },
  {
    heading: "Discount %",
    isSortable: true,
    sortDirection: "DESC",
    key: "percentDiscount",
    isSorted: true,
  },
];
  const [rangeHeader, setRangeHeader] = useState<any>(rangeHeaders);
  const [somethingWentWrong, setSomethingWentWrong] = useState<any>(false); //something went wrong variable
  const [schedule, setSchedule] = useState([
    {
      label: "Select",
      value: "",
    },
  ]);
  const [indexValue, setIndexValue] = useState(0);

  const [queueType, setQueueType] = useState([
    {
      label: "Select",
      value: "",
    },
  ]);
  const [queueStatusType, setQueueStatusType] = useState([
    {
      label: "Select",
      value: "",
    },
  ]);
  const [filterReq, setFilterReq] = useState<filterRequest>({});
  const [pageCount, setPageCount] = useState(0);
  const [isapprovalPopup, setIsapprovalPopup] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [isApprover, setIsApprover] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const [fullLoader, setFullLoader] = useState(false);
  const [packageCriteria, setPackageCriteria] = useState();
  const [criteriaRange, setcriteriaRange] = useState();
  const getReferenceandPricingQueueDetail = async () => {
    try {
      setLoader("Y");
      await getEmployeeInfoAndReferenceData(containerData, {
        type: ["SCHEDULE", "QUEUE_STATUS_TYPE", "QUEUE_TYPE"],
      }).then((rep: any) => {
        if (rep.status == 200) {
          sessionStorage.setItem("EmployeeId", rep.employeeId);
          sessionStorage.setItem("userName", rep.userName);
          console.log(
            validateApprover(rep.menuConfigArray),
            "the approver is in queu",
            rep.menuConfigArray
          );

          setIsApprover(validateApprover(rep.menuConfigArray));
          setSchedule(
            formatDropdown(
              rep.dropdownData.schedule.sort((a: any, b: any) =>
                a["refCode"] < b["refCode"] ? -1 : 1
              ),
              "refCode",
              "refCode"
            )
          );
          setQueueType(
            formatDropdown(
              rep.dropdownData.queueType.sort((a: any, b: any) =>
                a["refCode"] < b["refCode"] ? -1 : 1
              ),
              "refCode",
              "refCode"
            )
          );
          setQueueStatusType(
            formatDropdown(
              rep.dropdownData.queueStatusType.sort((a: any, b: any) =>
                a["refCode"] < b["refCode"] ? -1 : 1
              ),
              "refCode",
              "refCode"
            )
          );
          setIsFranchiseeUser(rep.isFranchiseeUser);
          setCompanyCode(rep.companyCode);
          if (rep.isFranchiseeUser) {
            if (rep.companyCode.length)
              fetchPricingQueueDetail({ companyCode: [rep.companyCode[0]] });
            else
              throw new Error(
                "CompanyCode is not supposed to be empty for an franchiseeUser"
              );
          } else {
            fetchPricingQueueDetail();
          }
          
        } else {
          setLoader(true);
          setSomethingWentWrong(true);
        }
      });
    } catch (err) {
      console.log(err, "the error in 724");
      setLoader(true);
      setSomethingWentWrong(true);
    }
  };
  const formatDropdown = (req: any, val1: string, val2: string) => {
    let arr: any = [
      {
        label: "Select",
        value: "",
      },
    ];
    req.forEach((item: any) => {
      arr.push({
        label: transformString(item[val1]),
        value: item[val2],
      });
    });

    return arr;
  };
  useEffect(() => {}, [tableBody]);
  const fetchPricingQueueDetail = async (params: filterRequest = {}) => {
    try {
      setLoader("Y");
      setTotalRowCount(0);
      let req: filterRequest = filterReq;
      req.offset = params.offset
        ? `${params.offset}`
        : filterReq?.offset?.toString();
      req.companyCode = params?.companyCode ? params.companyCode : undefined;
      req.limit = "25";
      const rep: any = await fetchPricingQueueAPI(req);
      console.log(rep, "The rrep from the queue");
      if (rep.data.pricingQueueDetails && rep.status == 200) {
        setTotalRowCount(rep.data.totalRecordsCount);
        setPageCount(Math.ceil(rep.data.totalRecordsCount / 25));
        setTableBody(rep.data.pricingQueueDetails);
        setNoRecords(false);
      } else if (rep.data == "") {
        setNoRecords(true);
        setTotalRowCount(0);
      } else {
        setSomethingWentWrong(true);
      }
    } catch (err) {
      setSomethingWentWrong(true);
      console.log("err", err);
    } finally {
      setLoader(true);
    }
  };
  return (
    <PricingQueueContext.Provider
      value={{
        sortDirection,
        noRecords,
        setNoRecords,
        IsPackage,
        setIsPackage,
        alterPopUp,
        setAlterPopUp,
        indexValue,
        setIndexValue,
        packageCriteria,
        setPackageCriteria,
        companyCode,
        setCompanyCode,
        tableHeader,
        isRejected,
        setIsRejected,
        setTableHeader,
        loader,
        setLoader,
        totalRowCount,
        setTotalRowCount,
        isapprovalPopup,
        setIsapprovalPopup,
        filterReq,
        setFilterReq,
        pageNumber,
        setPageNumber,
        pageCount,
        setPageCount,
        getReferenceandPricingQueueDetail,
        tableBody,
        setTableBody,
        hideMainPage,
        setHideMainPage,
        schedule,
        criteriaRange, setcriteriaRange,
        setSchedule,
        comments,
        setComments,
        queueType,
        setQueueType,
        queueStatusType,
        setQueueStatusType,
        zoneDetails,
        autotermRange,
        setAutotermRange,
        setZoneDetails,
        isAlterOpen,
        setIsAlterOpen,
        fetchPricingQueueDetail,
        approvalPopup,
        rangeHeader,
        setRangeHeader,
        fullLoader,
        setFullLoader,
        somethingWentWrong,
        setSomethingWentWrong,
        isApprover,
        setIsApprover,
        setApprovalPopup,
        isFranchiseeUser,
        setIsFranchiseeUser,
        isApproved,
        setIsApproved,
      }}
    >
      {props.children}
    </PricingQueueContext.Provider>
  );
}
