/* eslint-disable */
export enum AppRoute {
  Index = '/',
  Upload = '/pricing/upload',
  Report = '/pricing/report',
  StoreZone = '/pricing/storezone',
  Zone = '/pricing/zone',
  Pricing = '/pricing/manage',
  AutoTerm = '/pricing/autoterm',
  Package = '/pricing/package',
  PricingQueue = '/pricing/queue',
  PromoScheduler='/pricing/promoscheduler',
  SecondaryCriteria = '/pricing/secondarycriteria'
}