/* eslint-disable */
import { AxiosResponse } from 'axios';
import { APIError } from '../types/types';

export const sanitizeURL = (url: string): string => {
  if (!url) {
    return '';
  }

  const badURLRegex = RegExp('^((https)|(http)):/{3,}');
  const isBadURL = badURLRegex.test(url);

  if (isBadURL) {
    return 'https://' + url.replace(badURLRegex, '');
  }

  return url;
};

export const getErrorMessage = (response?: AxiosResponse<APIError>) => {
  if (response?.status === 400 && response?.data.errors[0].code) {
    return response?.data.errors[0].message;
  }

  return null;
};

export function transformString(input: string): string {
  const trimmedInput = input.trim();
  if (!trimmedInput) {
    return '';
  }
  switch (trimmedInput) {
    case "STOREZONE": return "StoreZone";
    case "CANCEL": return "Cancelled";
    case "PARTIAL": return "Partially Completed";
    case "VERIFYPRC": return "Verification In-progress";
    case "WAITING": return "Waiting for Approval";
    case "REMOVE" : return "Remove Pricing";
    case "SECONDARY" : return "Secondary Criteria";
    default: return trimmedInput.charAt(0).toUpperCase() + trimmedInput.slice(1).toLowerCase();
  }
}