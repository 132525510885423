/* eslint-disable */
import React from "react";
import { Route, Switch } from "react-router-dom";
//Redirect,
import { AppRoute } from "../config/route-config";
import { UserProvider } from "../context/user/UserProvider";
import { PermissionsProvider } from "../context/permission/PermissionsProvider";
import { UserFetcher } from "../components/UserFetcher/UserFetcher";
import { PricingUpload } from "../components/Pricing/PricingUpload";
import PricingReport from "../components/Pricing/PricingReport";
import Zone from "../components/Pricing/Zone";
import StoreZone from "../components/Pricing/StoreZone";
import PricingContainer from "../components/Pricing/Pricing/PricingParentContainer";
import PricingQueue from "../components/Pricing/PricingQueue/PricingQueue";
import AutoTerm from "../components/Pricing/autoTerm/AutoTerm";
import PromoScheduler from "../components/PromoScheduler/PromoScheduler";
import Package from '../components/Pricing/Packages/PackageParentContainer';


import SecondaryCriteria from "../components/Pricing/SecondaryCriteria/SecondaryCriteria";
import { CriteriaContextProvider } from "../components/Pricing/SecondaryCriteria/CriteriaContext";

export const routesTestId = "routesTestId";

export const Routes = () => (
  <UserProvider>
    <PermissionsProvider>
      <UserFetcher />
      <div data-testid={routesTestId}>
        <Switch>
          {/* <Route exact path={AppRoute.Index}>
            <Redirect to={AppRoute.Placeholder1} />
          </Route> */}
          <Route exact path={AppRoute.Upload}>
            <PricingUpload />
          </Route>
          <Route exact path={AppRoute.Report}>
            <PricingReport />
          </Route>
          <Route exact path={AppRoute.Zone}>
            <Zone />
          </Route>
          <Route exact path={AppRoute.Pricing}>
            <PricingContainer />
          </Route>
          <Route exact path={AppRoute.StoreZone}>
            <StoreZone />
          </Route>
          <Route exact path={AppRoute.PricingQueue}>
            <PricingQueue />
          </Route>
          <Route exact path={AppRoute.AutoTerm}>
            <AutoTerm />
          </Route>
          <Route exact path={AppRoute.Package}>
          <Package/>
        </Route>
          <Route exact path={AppRoute.PromoScheduler}>
            <PromoScheduler />
          </Route>
          <Route exact path={AppRoute.SecondaryCriteria}>
            <CriteriaContextProvider>
            <SecondaryCriteria />
            </CriteriaContextProvider>
          </Route>
        </Switch>
      </div>
    </PermissionsProvider>
  </UserProvider>
);
	