/* eslint-disable */
/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/prefer-immediate-return */
import { makeStyles, RACCOLOR } from "@rentacenter/racstrap";
export const globalStyles = () => {
  const useClasses = makeStyles((theme) => ({
    "@global": {
      "*::-webkit-scrollbar": {
        width: "10px",
        height: "10px",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "rgb(189, 189, 196)",
        borderRadius: "20px",
        border: "3px solid rgb(206, 204, 201)",
      },
      "*::-webkit-scrollbar-track": {
        background: "rgb(245, 243, 239)",
      },
    },

    /** ************************************************************ */
    /** ** Setting up the default MARGIN values - starts here **** */
    /** ************************************************************ */
    m0: {
      margin: theme.typography.pxToRem(0),
    },
    m1: {
      margin: theme.typography.pxToRem(4),
    },
    m2: {
      margin: theme.typography.pxToRem(8),
    },
    m3: {
      margin: theme.typography.pxToRem(16),
    },
    m4: {
      margin: theme.typography.pxToRem(24),
    },
    m5: {
      margin: theme.typography.pxToRem(48),
    },
    mAuto: {
      margin: "auto",
    },

    mr0: {
      marginRight: theme.typography.pxToRem(0),
    },
    mr1: {
      marginRight: theme.typography.pxToRem(4),
    },
    mr2: {
      marginRight: theme.typography.pxToRem(8),
    },
    mr3: {
      marginRight: theme.typography.pxToRem(16),
    },
    mr4: {
      marginRight: theme.typography.pxToRem(24),
    },
    mr5: {
      marginRight: theme.typography.pxToRem(48),
    },
    mrAuto: {
      marginRight: "auto",
    },

    ml0: {
      marginLeft: theme.typography.pxToRem(0),
    },
    ml1: {
      marginLeft: theme.typography.pxToRem(4),
    },
    ml2: {
      marginLeft: theme.typography.pxToRem(8),
    },
    ml3: {
      marginLeft: theme.typography.pxToRem(16),
    },
    ml4: {
      marginLeft: theme.typography.pxToRem(24),
    },
    ml5: {
      marginLeft: theme.typography.pxToRem(48),
    },
    mlAuto: {
      marginLeft: "auto",
    },

    mt0: {
      marginTop: theme.typography.pxToRem(0),
    },
    mt1: {
      marginTop: theme.typography.pxToRem(4),
    },
    mt2: {
      marginTop: theme.typography.pxToRem(8),
    },
    mt3: {
      marginTop: theme.typography.pxToRem(16),
    },
    mt4: {
      marginTop: theme.typography.pxToRem(24),
    },
    mt5: {
      marginTop: theme.typography.pxToRem(48),
    },
    mtAuto: {
      marginTop: "auto",
    },

    mb0: {
      marginBottom: theme.typography.pxToRem(0),
    },
    mb1: {
      marginBottom: theme.typography.pxToRem(4),
    },
    mb2: {
      marginBottom: theme.typography.pxToRem(8),
    },
    mb3: {
      marginBottom: theme.typography.pxToRem(16),
    },
    mb4: {
      marginBottom: theme.typography.pxToRem(24),
    },
    mb5: {
      marginBottom: theme.typography.pxToRem(48),
    },
    mb6: {
      marginBottom: theme.typography.pxToRem(200),
    },
    mbAuto: {
      marginBottom: "auto",
    },
    mbFooterSpacing: {
      marginBottom: theme.typography.pxToRem(130),
    },

    my0: {
      marginTop: theme.typography.pxToRem(0),
      marginBottom: theme.typography.pxToRem(0),
    },
    my1: {
      marginTop: theme.typography.pxToRem(4),
      marginBottom: theme.typography.pxToRem(4),
    },
    my2: {
      marginTop: theme.typography.pxToRem(8),
      marginBottom: theme.typography.pxToRem(8),
    },
    my3: {
      marginTop: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(16),
    },
    my4: {
      marginTop: theme.typography.pxToRem(24),
      marginBottom: theme.typography.pxToRem(24),
    },
    my5: {
      marginTop: theme.typography.pxToRem(48),
      marginBottom: theme.typography.pxToRem(48),
    },
    myAuto: {
      marginTop: "auto",
      marginBottom: "auto",
    },

    mx0: {
      marginLeft: theme.typography.pxToRem(0),
      marginRight: theme.typography.pxToRem(0),
    },
    mx1: {
      marginLeft: theme.typography.pxToRem(4),
      marginRight: theme.typography.pxToRem(4),
    },
    mx2: {
      marginLeft: theme.typography.pxToRem(8),
      marginRight: theme.typography.pxToRem(8),
    },
    mx3: {
      marginLeft: theme.typography.pxToRem(16),
      marginRight: theme.typography.pxToRem(16),
    },
    mx4: {
      marginLeft: theme.typography.pxToRem(24),
      marginRight: theme.typography.pxToRem(24),
    },
    mx5: {
      marginLeft: theme.typography.pxToRem(48),
      marginRight: theme.typography.pxToRem(48),
    },
    mxAuto: {
      marginLeft: "auto",
      marginRight: "auto",
    },
    /** ************************************************************ */
    /** ** Setting up the default MARGIN values - ends here **** */
    /** ************************************************************ */

    /** ************************************************************ */
    /** ** Setting up the default PADDING values - starts here **** */
    /** ************************************************************ */
    p0: {
      padding: "0 !important",
    },
    p1: {
      padding: theme.typography.pxToRem(4),
    },
    p2: {
      padding: theme.typography.pxToRem(8),
    },
    p3: {
      padding: theme.typography.pxToRem(16),
    },
    p4: {
      padding: theme.typography.pxToRem(24),
    },
    p5: {
      padding: theme.typography.pxToRem(48),
    },
    pAuto: {
      padding: "auto",
    },

    pr0: {
      paddingRight: theme.typography.pxToRem(0),
    },
    pr1: {
      paddingRight: theme.typography.pxToRem(4),
    },
    pr2: {
      paddingRight: theme.typography.pxToRem(8),
    },
    pr3: {
      paddingRight: theme.typography.pxToRem(16),
    },
    pr4: {
      paddingRight: theme.typography.pxToRem(24),
    },
    pr5: {
      paddingRight: theme.typography.pxToRem(48),
    },
    prAuto: {
      paddingRight: "auto",
    },

    pl0: {
      paddingLeft: theme.typography.pxToRem(0),
    },
    pl1: {
      paddingLeft: theme.typography.pxToRem(4),
    },
    pl2: {
      paddingLeft: theme.typography.pxToRem(8),
    },
    pl3: {
      paddingLeft: theme.typography.pxToRem(16),
    },
    pl4: {
      paddingLeft: theme.typography.pxToRem(24),
    },
    pl5: {
      paddingLeft: theme.typography.pxToRem(48),
    },
    plAuto: {
      paddingLeft: "auto",
    },

    pt0: {
      paddingTop: theme.typography.pxToRem(0),
    },
    pt1: {
      paddingTop: theme.typography.pxToRem(4),
    },
    pt2: {
      paddingTop: theme.typography.pxToRem(8),
    },
    pt3: {
      paddingTop: theme.typography.pxToRem(16),
    },
    pt4: {
      paddingTop: theme.typography.pxToRem(24),
    },
    pt5: {
      paddingTop: theme.typography.pxToRem(48),
    },
    ptAuto: {
      paddingTop: "auto",
    },

    pb0: {
      paddingBottom: "theme.typography.pxToRem(0)",
    },
    pb1: {
      paddingBottom: theme.typography.pxToRem(4),
    },
    pb2: {
      paddingBottom: theme.typography.pxToRem(8),
    },
    pb3: {
      paddingBottom: theme.typography.pxToRem(16),
    },
    pb4: {
      paddingBottom: theme.typography.pxToRem(24),
    },
    pb5: {
      paddingBottom: theme.typography.pxToRem(48),
    },
    pbAuto: {
      paddingBottom: "auto",
    },

    py0: {
      paddingTop: theme.typography.pxToRem(0),
      paddingBottom: theme.typography.pxToRem(0),
    },
    py1: {
      paddingTop: theme.typography.pxToRem(4),
      paddingBottom: theme.typography.pxToRem(4),
    },
    py2: {
      paddingTop: theme.typography.pxToRem(8),
      paddingBottom: theme.typography.pxToRem(8),
    },
    py3: {
      paddingTop: theme.typography.pxToRem(16),
      paddingBottom: theme.typography.pxToRem(16),
    },
    py4: {
      paddingTop: theme.typography.pxToRem(24),
      paddingBottom: theme.typography.pxToRem(24),
    },
    py5: {
      paddingTop: theme.typography.pxToRem(48),
      paddingBottom: theme.typography.pxToRem(48),
    },
    pyAuto: {
      paddingTop: "auto",
      paddingBottom: "auto",
    },

    px0: {
      paddingLeft: theme.typography.pxToRem(0),
      paddingRight: theme.typography.pxToRem(0),
    },
    px1: {
      paddingLeft: theme.typography.pxToRem(4),
      paddingRight: theme.typography.pxToRem(4),
    },
    px2: {
      paddingLeft: theme.typography.pxToRem(8),
      paddingRight: theme.typography.pxToRem(8),
    },
    px3: {
      paddingLeft: theme.typography.pxToRem(16),
      paddingRight: theme.typography.pxToRem(16),
    },
    px4: {
      paddingLeft: theme.typography.pxToRem(24),
      paddingRight: theme.typography.pxToRem(24),
    },
    px5: {
      paddingLeft: theme.typography.pxToRem(48),
      paddingRight: theme.typography.pxToRem(48),
    },
    pxAuto: {
      paddingLeft: "auto",
      paddingRight: "auto",
    },

    pbCardContentCustom: {
      paddingBottom: "0.5rem !important",
    },

    /** ************************************************************ */
    /** ** Setting up the default PADDING values - ends here **** */
    /** ************************************************************ */

    /** ************************************************************ */
    /** ** Setting up the default FONT values - starts here **** */
    /** ************************************************************ */
    fs1: {
      fontSize: theme.typography.pxToRem(40),
    },
    fs2: {
      fontSize: theme.typography.pxToRem(32),
    },
    fs3: {
      fontSize: theme.typography.pxToRem(28),
    },
    fs4: {
      fontSize: theme.typography.pxToRem(24),
    },
    fs5: {
      fontSize: theme.typography.pxToRem(20),
    },
    fs6: {
      fontSize: theme.typography.pxToRem(16),
    },
    fs7: {
      fontSize: theme.typography.pxToRem(14),
    },
    fs8: {
      fontSize: theme.typography.pxToRem(12),
    },
    fontSemiBold: {
      fontFamily: "OpenSans-semibold",
    },
    fontBold: {
      fontFamily: "OpenSans-bold",
    },
    /** ************************************************************ */
    /** ** Setting up the default FONT values - ends here **** */
    /** ************************************************************ */

    /** ************************************************************ */
    /** ** Setting up the BACKGROUND values - starts here **** */
    /** ************************************************************ */
    bgPrimary: {
      backgroundColor: "#2179FE",
    },
    bgTransparent: {
      backgroundColor: "transparent",
    },
    bgLightBlue: {
      backgroundColor: "#EFF4FF",
    },
    bgLightGrey: {
      backgroundColor: "#fafafa",
    },
    bgRed: {
      backgroundColor: "#e85c5c",
    },
    bgPaleYellow: {
      backgroundColor: "#d58b00",
    },
    /** ************************************************************ */
    /** ** Setting up the BACKGROUND values - ends here **** */
    /** ************************************************************ */

    /** ************************************************************ */
    /** ** Setting up the COLOR values - starts here **** */
    /** ************************************************************ */
    textGrey: {
      color: "#4A5174",
    },
    darkGrey: {
      background: "#B2B2B2 !important",
    },
    textBlack: {
      color: "#000000",
    },
    yellow: {
      background: "#FAAD00 !important",
    },
    rejected: {
      background: '#980000 !important'
    },
    textWhite: {
      color: "#ffffff",
    },
    textBlue: {
      color: "#2179FE",
    },
    textViolet: {
      color: "#2e31be",
    },
    textDisabled: {
      color: "#818181",
    },
    /** ************************************************************ */
    /** ** Setting up the COLOR values - ends here **** */
    /** ************************************************************ */

    /** ************************************************************ */
    /** ** Setting up the TEXT TREATMNET values - starts here **** */
    /** ************************************************************ */
    textIndent: {
      textIndent: "-9999px",
    },

    justifySpaceBetween: {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      justifyContent: "space-between",
    },
    textCenter: {
      textAlign: "center",
    },
    textRight: {
      textAlign: "right",
    },
    textLeft: {
      textAlign: "left",
    },
    gridLeft: {
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    ml5px: {
      marginLeft: '5px'
    },
    /** ************************************************************ */
    /** ** Setting up the TEXT TREATMNET values - ends here **** */
    /** ************************************************************ */

    /** ************************************************************ */
    /** ** Setting up the BORDER and RADIUS values - starts here **** */
    /** ************************************************************ */
    borderBottom: {
      borderBottom: "1px solid #d9d9d9",
    },
    borderLeftViolet: {
      borderLeft: "3px solid #2d31be",
    },
    borderRadius0: {
      borderRadius: "0 !important",
    },
    borderRadius5: {
      borderRadius: theme.typography.pxToRem(5),
    },
    borderRadius10: {
      borderRadius: theme.typography.pxToRem(10),
    },
    borderRadius50: {
      borderRadius: theme.typography.pxToRem(50),
    },
    borderNone: {
      border: theme.typography.pxToRem(0),
    },
    /** ************************************************************ */
    /** *** Setting up the BORDER and RADIUS values - ends here **** */
    /** ************************************************************ */

    /** ************************************************************ */
    /** ** Setting up the COMPONENT ALIGNMENT - starts here **** */
    /** ************************************************************ */
    componentLeft: {
      justifyContent: "flex-start",
      alignItems: "center",
      display: "flex",
    },
    componentCenter: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
    componentRight: {
      justifyContent: "flex-end",
      alignItems: "center",
      display: "flex",
    },
    componentSpaceBetween: {
      justifyContent: "space-between",
      alignItems: "center",
      display: "flex",
    },
    alignCenter: {
      alignItems: "center",
    },
    dflex: {
      display: "flex",
    },
    /** ************************************************************ */
    /** ** Setting up the COMPONENT ALIGNMENT - starts here **** */
    /** ************************************************************ */

    shadow0: {
      boxShadow: "none",
    },

    w100: {
      width: "100%",
    },
    w95: {
      width: "95%",
    },
    widthAuto: {
      width: "auto",
    },

    positionRelative: {
      position: "relative",
    },

    positionAbsolute: {
      position: "absolute",
    },

    racCard: {
      borderRadius: theme.typography.pxToRem(10),
      boxShadow: "0 0 3px #d5d4d4",
    },

    /** ** Need to revisit the CSS below *** */
    denominationBtn: {
      paddingTop: theme.typography.pxToRem(4),
      paddingBottom: theme.typography.pxToRem(4),
      paddingLeft: theme.typography.pxToRem(24),
      paddingRight: theme.typography.pxToRem(24),
      border: "1px solid #2468ff",
      fontFamily: "Opensans-bold",
      minWidth: "100px",
    },
    denominationTextBox: {
      width: "115px",
    },
    fixedFooter: {
      position: "fixed",
      bottom: 0,
      left: 0,
      display: "flex",
      justifyContent: "space-between",
      padding: theme.typography.pxToRem(16),
      backgroundColor: "#ffffff",
      boxShadow: "0 0 3px #d9d9d9",
    },

    racGlobalSearchBtn: {
      cursor: "pointer",
      width: "25px",
      float: "left",
      paddingTop: "0.2rem",
    },

    customPageSize: {
      width: "400px",
    },

    /** ** Filter styles *** */

    filterBtn: {
      padding: "10px",
      // eslint-disable-next-line sonarjs/no-duplicate-string
      borderColor: "#D9E2ED !important",
      minWidth: "50px",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    searchSpacing: {
      "& input": {
        borderTopRightRadius: "unset",
        borderBottomRightRadius: "unset",
        borderRight: "none",
        borderColor: "#D9E2ED !important",
        padding: "10px",
        "&:focus": {
          borderColor: "#D9E2ED !important",
          boxShadow: "unset !important",
          borderTopRightRadius: "unset",
          borderBottomRightRadius: "unset",
        },
      },
    },

    filterDropDown: {
      zIndex: 1,
      width: "400px",
      right: "0",
      left: "auto !important",
    },

    InlineFlex: {
      display: "inline-flex",
    },
    TextUnderline: {
      textDecoration: "underline",
    },
    BtnHover: {
      "&:hover": {
        backgroundColor: "#fff",
      },
    },
    overFlowVisible: {
      overflow: "visible !important",
    },
    inputGroup: {
      position: "relative",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "strech",
      width: "100%",
    },
    searchBar: {
      "&:focus": {
        boxShadow: "0 0 0 0.25rem rgb(0 123 255 / 25%) !important",
      },
    },
    searchBarIcon: {
      border: "1px solid #D9E2ED",
      borderLeft: "none",
      borderTopLeftRadius: "unset",
      borderBottomLeftRadius: "unset",
      minWidth: "32px !important",

      "&:hover": {
        borderColor: "#D9E2ED !important",
        boxShadow: "unset !important",
        backgroundColor: "unset",
      },
    },

    errorPopupStyle: {
      "& .MuiDialog-scrollPaper": {
        alignItems: "start !important",
      },
      "& div": {
        "& div": {
          "& div:nth-child(1)": {
            paddingTop: "0px !important",
            paddingBottom: "0px !important",
            marginTop: "15px !important",
          },
          "& div:nth-child(2)": {
            paddingTop: "0px !important",
          },
        },
      },
    },
    statusLegend: {
      display: "inline-block",
      height: "8px",
      width: "8px",
      borderRadius: "16px",
      marginRight: "5px",
    },
    zoneDetailGrid: {
      overflowY: "auto",
      height: "58vh",
      "& th": {
        position: "sticky",
        top: 0,
        backgroundColor: "#ffffff",
        fontFamily: "OpenSans-bold",
        zIndex: 1,
        padding: "0.7rem 0.8rem",
        whiteSpace: "nowrap",
        fontSize: "15px",
        fontWeight: "400",
        color: "#111111",
      },
      "& tbody": {
        "& tr": {
          position: "sticky",
          top: 10,
          backgroundColor: "#ffffff",
        },
        "& td": {
          fontFamily: "OpenSans-semibold",
          color: "#3c454f",
          padding: "0.7rem 0.8rem",
          fontWeight: "400",
        },
      },
    },

    storeZoneDetailGrid: {
      overflowY: "auto",
      height: "58vh",
      "& th": {
        position: "sticky",
        top: 0,
        backgroundColor: "#ffffff",
        fontFamily: "OpenSans-bold",
        zIndex: 1,
        padding: "0.7rem 0.8rem",
        whiteSpace: "nowrap",
        fontSize: "15px",
        fontWeight: "400",
        color: "#111111",
      },
      "& tbody": {
        "& tr": {
          position: "sticky",
          top: 10,
          backgroundColor: "#ffffff",
        },
        "& td": {
          fontFamily: "OpenSans-semibold",
          color: "#3c454f",
          padding: "0.7rem 0.8rem",
          fontWeight: "400",
        },
      },
    },
    zoneDetailHeight: {
      maxHeight: '58vh',
      overflowY: "auto",
      "& th": {
        position: "sticky",
        top: 0,
        backgroundColor: "#ffffff",
        fontFamily: "OpenSans-bold",
        zIndex: 1,
        padding: "0.7rem 0.8rem",
        whiteSpace: "nowrap",
        fontSize: "15px",
        fontWeight: "400",
        color: "#111111",
      },
      "& tbody": {
        "& tr": {
          position: "sticky",
          top: 10,
          backgroundColor: "#ffffff",
        },
        "& td": {
          fontFamily: "OpenSans-semibold",
          color: "#3c454f",
          padding: "0.7rem 0.8rem",
          fontWeight: "400",
        },
      },
    },

    tableCell: {
      "& .MuiTableCell-root": {
        fontWeight: "400 !important",
        fontSize: "0.85rem",
      },
    },

    newZoneModal: {
      "& .MuiDialog-paper": {
        // this is already existing so using the & symbol.
        width: "750px !important",
      },
      "& .MuiDialog-scrollPaper": {
        alignItems: "start !important",
      },
    },

    okPopupStyle: {
      "& .MuiDialog-scrollPaper": {
        alignItems: "start !important",
      },
      "& div": {
        "& div": {
          "& div:nth-child(1)": {
            paddingBottom: "0px !important",
          },
          "& div:nth-child(2)": {
            paddingTop: "0px !important",
          },
        },
      },
    },
    fixTableHeadAgrTrsRec: {
      overflowY: "auto",
      maxHeight: "67vh",
      "& th": {
        position: "sticky",
        top: 0,
        backgroundColor: "#ffffff",
        fontFamily: "OpenSans-bold",
        zIndex: 1,
        padding: "0.9375rem 0.2rem",
        whiteSpace: "nowrap",
        fontSize: "15px",
        fontWeight: "400",
        color: "#111111",
      },
      "& tbody": {
        "& tr": {
          position: "sticky",
          top: 0,
          backgroundColor: "#ffffff",
        },
        "& td": {
          fontFamily: "OpenSans-semibold  !important",
          color: "#3c454f",
          padding: "0.9375rem 0.2rem",
        },
      },
      disableme: {
        pointerEvents: "none",
        opacity: 0.9,
      },
    },
    PricingGrid: {
      "& thead": {
        position: "sticky",
        top: "0",
        zIndex: "1",
        backgroundColor: "#FFF",
      },
      "& tr": {
        "& th": {
          padding: "12px 20px !important",
          backgroundColor: "#ffffff",
          fontFamily: "OpenSans-bold",
          fontSize: "0.8rem",
          // padding: '0.9375rem 3.5rem',
        },
        "& td": {
          padding: "12px 20px !important",
          fontFamily: "OpenSans-semibold",
          color: "#3c454f",
          fontSize: "0.8rem",

          // padding: '0.7rem 3.5rem',
        },
        "& th:nth-child(1)": {
          position: "sticky",
          left: "0",
          backgroundColor: "#FFF",
          paddingLeft: '0px !important',
          paddingRight: '0px !important',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          minHeight: '40px !important'
        },
        /*  "& th:nth-child(2)": {
          position: "sticky",
          left: "74px",
          backgroundColor: "#FFF",
        },
        "& th:nth-child(3)": {
          position: "sticky",
          left: "164px",
          backgroundColor: "#FFF",
        },
        "& th:nth-child(4)": {
          position: "sticky",
          left: "306px",
          backgroundColor: "#FFF",

          "&:after" :{
            position: "absolute",
            top: 0,
            content: "' '",
            width: '1px',
            display: "block",
            bottom: 0,
            right: 0,
            boxShadow: '2px 0px 3px #e2e2e2',
          }
        },  */
        "& td:nth-child(1)": {
          position: "sticky",
          left: "0",
          backgroundColor: "#FFF",
          paddingLeft: '0px !important',
          paddingRight: '0px !important',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        },
        /* "& td:nth-child(2)": {
           position: "sticky",
           left: "74px",
           backgroundColor: "#FFF",
         },
         "& td:nth-child(3)": {
           position: "sticky",
           left: "164px",
           backgroundColor: "#FFF",
         },
         "& td:nth-child(4)": {
           position: "sticky",
           left: "306px",
           backgroundColor: "#FFF",
           "&:after" :{
             content: "''",  // Use double quotes for content property
             position: "absolute",
             top: 0,
             width: '1px',
             display: "block",
             bottom: 0,
             right: 0,
             boxShadow: '2px 0px 3px #e2e2e2',  // Add missing semicolon at the end
           }
         }, */
      },
    },
    Orangetag: {
      position: "relative",
      "&::before": {
        content: ' " " ',
        position: "absolute",
        display: "block",
        top: "8px",
        bottom: "8px",
        left: "0",
        backgroundColor: "#FF8226",
        width: "4px",
        borderTopRightRadius: "6px",
        borderBottomRightRadius: "6px",
      },
    },
    Purpletag: {
      position: "relative",
      "&::before": {
        content: ' " " ',
        position: "absolute",
        display: "block",
        top: "8px",
        bottom: "8px",
        left: "0",
        backgroundColor: "#BB01FF",
        width: "4px",
        borderTopRightRadius: "6px",
        borderBottomRightRadius: "6px",
      },
    },
    CustomListStyle: {
      "& .MuiListItem-gutters": {
        paddingLeft: "3px",
      },
    },
    attachmentImageStyle: {
      "& div": {
        "& div": {
          "& .MuiDialogTitle-root": {
            paddingBottom: "0px",
          },
        },
      },
    },
    dialogBox: {
      "& .MuiDialog-paperScrollPaper": {
        maxWidth: "820px ",
        // '& .MuiDialogTitle-root':{
        //   paddingLeft:'8px'
        // }
        '& .MuiDialogContent-root': {
          paddingTop: '0px'
        }
      },
    },
    attachmentsPopUpStyles: {
      "& .MuiDialog-paperWidthLg": {
        width: "800px",
      },
    },
    masterWhiteLoader: {
      position: "fixed",
      background: "#f7f5f5",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      opacity: 0.6,
      zIndex: 1000,
      textAlign: "center",
      margin: "0px 0px",
    },
    whiteLoaderCenter: {
      display: "block",
      position: "fixed",
      zIndex: 9999999,
      top: "40%",
      right: "50%",
    },
    filterPricingQueueDropDown: {
      zIndex: 99,
      width: "400px",
      right: "10px",
      top: "52px",
      left: "auto !important",
    },
    modelCardZoneDetail: {
      '& .MuiDialog-paperScrollPaper': {
        width: '300px !important',
        // background:'red'
      }
    },
    logoResponsive: {},
    spacerMargin: {
      margin: theme.typography.pxToRem(8),
    },
    spacerMT1: {
      marginTop: theme.typography.pxToRem(4),
    },
    customTitle: {
      padding: '16px 24px 0px 24px'
    },
    racLoginAlert: {
      color: `${RACCOLOR.MAXIMUM_RED}`,
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
    },
    formLabel: {
      marginBottom: '0.2rem',
      color: '#111111',
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
    },
    floatRight: {
      float: 'right',
    },
    spacerMT5: {
      marginTop: theme.typography.pxToRem(48),
    },
    spacerMB3: {
      marginBottom: theme.typography.pxToRem(24),
    },
    disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
    spacerME2: {
      marginRight: theme.typography.pxToRem(16),
    },
    racErrorIcon: {
      width: '50px',
    },
    racErrorFunction: {
      fontFamily: 'OpenSans-semibold',
      marginBottom: '20px',
      marginTop: '10px',
    },
    racPinContainer: {
      margin: '0 auto',
      width: '85%',
      paddingLeft: '15px',
    },
    racPinLabel: {
      fontFamily: 'OpenSans-semibold',
      color: `${RACCOLOR.RICH_BLACK}`,
      marginLeft: '0.5rem',
      marginBottom: '3px',
    },
    racPinError: {
      color: `${RACCOLOR.MAXIMUM_RED}`,
      marginTop: '5px',
      marginLeft: '0.5rem',
      fontFamily: 'OpenSans-semibold',
    },
    racLoader: {
      position: 'absolute',
      top: '45%',
      left: '50%',
      transform: 'translate(-50%, 85%)',
    },
    paddingButtons: {
      paddingRight: '45px',
    },

    racRemoteLoginAlert: {
      color: `${RACCOLOR.MAXIMUM_RED}`,
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
      textAlign: 'center',
      marginTop: '15px',
    },
    racRemoteLogin: {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fontFamily: 'OpenSans-semibold',
      textAlign: 'center',
      margin: '25px 15px',
      fontSize: theme.typography.pxToRem(14),
    },
    buttonMargin: {
      marginRight: '50px',
    },
    breadcrumb: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: 0,
      marginBottom: theme.typography.pxToRem(10),
      listStyle: 'none',
      marginTop: '10px'
    },
    breadcrumbItem: {
      width: 'auto',
      fontFamily: 'OpenSans-semibold',
      fontSize: '14px',
    },
    breadcrumbItemActive: {
      width: "auto",
      fontFamily: "OpenSans-semibold",
      fontSize: "14px",
      color: " #8E8E8E",
      paddingLeft: "15px",
      paddingRight: 0,
    },
    breadcrumbItemActive1: {
      width: "auto",
      fontFamily: "OpenSans-semibold",
      fontSize: "14px",
      color: "#2179FE",
      paddingLeft: "15px",
      paddingRight: 0,
    },
    breadcrumbArrow: {
      '&:before': {
        content: '""',
        width: 0,
        height: 0,
        borderTop: '5px solid transparent',
        borderLeft: '7px solid #707070',
        borderBottom: '5px solid transparent',
        marginTop: 0,
        float: 'left',
        paddingRight: '13px',
        color: '#6c757d',
      },
    },

    promoGrid: {
      overflowY: "auto",
      minHeight: "55vh",
      maxHeight: "55vh",
      "& th": {
        position: "sticky",
        top: 0,
        backgroundColor: "#ffffff",
        fontFamily: "OpenSans-bold",
        zIndex: 1,
        padding: "0.7rem 0.8rem",
        whiteSpace: "nowrap",
        fontSize: "15px",
        fontWeight: "400",
        color: "#111111",
      },
      "& tbody": {
        "& tr": {
          position: "sticky",
          top: 10,
          backgroundColor: "#ffffff",
        },
        "& td": {
          fontFamily: "OpenSans-semibold",
          color: "#3c454f",
          padding: "0.7rem 0.8rem",
          fontWeight: "400",
        },
      },
    },
    promoGridLoader: {
      overflowY: "auto",
      minHeight: "64.75vh",
      maxHeight: "64.75vh",
      "& th": {
        position: "sticky",
        top: 0,
        backgroundColor: "#ffffff",
        fontFamily: "OpenSans-bold",
        zIndex: 1,
        padding: "0.7rem 0.8rem",
        whiteSpace: "nowrap",
        fontSize: "15px",
        fontWeight: "400",
        color: "#111111",
      },
      "& tbody": {
        "& tr": {
          position: "sticky",
          top: 10,
          backgroundColor: "#ffffff",
        },
        "& td": {
          fontFamily: "OpenSans-semibold",
          color: "#3c454f",
          padding: "0.7rem 0.8rem",
          fontWeight: "400",
        },
      },
    },
    associatedStoresGrid: {
      overflowY: "auto",
      maxHeight: "838px",
      "& th": {
        position: "sticky",
        top: 0,
        backgroundColor: "#ffffff",
        fontFamily: "OpenSans-bold",
        zIndex: 1,
        padding: "0.7rem 4.8rem 0.7rem 3.8rem",
        whiteSpace: "nowrap",
        fontSize: "15px",
        fontWeight: "400",
        color: "#111111",
      },
      "& tbody": {
        "& tr": {
          position: "sticky",
          top: 10,
          backgroundColor: "#ffffff",
        },
        "& td": {
          fontFamily: "OpenSans-semibold",
          color: "#3c454f",
          padding: "0.7rem 0.8rem 0.7rem 3.8rem",
          fontWeight: "400",
        },
      },
    },

    cutomTitlePinPopup: {
      '& h5': {
        marginLeft: '10%'
      }
    },
    criteriaGridStyles: {
      'makeStyles-p3': {
        width: '100%',
        background: 'red'
      },
      "& th:nth-child(1)": {
        width: '15.5%',
        minWidth: '15.5%'
      },
      "& td:nth-child(1)": {
        width: '15.5%',
        minWidth: '15.5%'
      },
      "& th:nth-child(2)": {
        width: '15.5%',
        minWidth: '15.5%'
      },
      "& td:nth-child(2)": {
        width: '15.5%',
        minWidth: '15.5%'
      },
      "& th:nth-child(3)": {
        width: '15.5%',
        minWidth: '15.5%'
      },
      "& td:nth-child(3)": {
        width: '15.5%',
        minWidth: '15.5%'
      },
      "& th:nth-child(4)": {
        width: '15.5%',
        minWidth: '15.5%'
      },
      "& td:nth-child(4)": {
        width: '15.5%',
        minWidth: '15.5%'
      },
      "& th:nth-child(5)": {
        width: '15.5%',
        minWidth: '15.5%'
      },
      "& td:nth-child(5)": {
        width: '15.5%',
        minWidth: '15.5%'
      },
      "& th:nth-child(6)": {
        width: '15.5%'
      },
      "& td:nth-child(6)": {
        width: '15.5%',
        minWidth: '15.5%'
      },

    },

    /** ** Filter styles *** */
    /* Something went wrong popup styles */
    customWarningPopupStyles: {
      '& div': {
        '& div': {
          maxWidth: '472px !important',
        }
      }
    },
    tooltipCustomStyles: {
      color: 'black !important'
    },
    CustomRACRadio: {
      '& span': {
        opacity: '1 !important',
        color: 'black !important'
      }
    }
    /* Something went wrong popup styles */
  }));
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useClasses();
  return classes;
};
