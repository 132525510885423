/* eslint-disable */

import { CardContent, Grid, RACButton, Typography, Card, RACTextbox, RACSelect } from '@rentacenter/racstrap';
import React, { useContext, useEffect, useState } from 'react';
import { SecondaryCriteriaStyle } from '../Styles/SecondaryCriteria';
import { CriteriaContext } from './CriteriaContext';
import { MultiSelect } from 'react-multi-select-component';
import { ReactComponent as RacFilter } from "../../../assets/images/filter-white.svg";
import { ReactComponent as AccordianOpen } from "../../../assets/images/up-arrow-filter.svg";
import { ReactComponent as AccordianClose } from "../../../assets/images/down-arrow-filter.svg";
import { secondaryCriteriaDetails } from '../../../api/user';
export default function SecondaryCriteriaFilter() {
    const classes = SecondaryCriteriaStyle();
    const {
        dropdownValues,
        setDropDownValues,
        loader,
        showComp, setShowComp,
        searchData, setSearchData,
        userInfo, secondaryCriteriaSearchContent, setSecondaryCriteriaSearchContent,
        searchSecondaryCriteriaDetails, dropDownSetter
    } = useContext(CriteriaContext);

    const [subDeptOptions, setSubDeptOptions] = useState<any>([{
        label: "Select",
        value: "",
        departmentNumber: ''
    }]);
    const [bracketOptions, setBracketOptions] = useState<any>([{
        label: "Select",
        value: "",
        subdepartmentNumber: ''
    }]);
    const [zoneSelected, setZoneSelected] = useState([]);
    const clearButtonDisable = (searchData.department ||
        searchData.subdepartment ||
        searchData.bracket ||
        searchData.zone ||
        searchData.ruleType ||
        searchData.secondaryCriteriaType ||
        searchData.priceParameter ||
        searchData.brand ||
        searchData.rms)


    console.log(searchData, 'searchDatasearchData')


    function onChangeSearchFields(e: any, name: string) {
        setSearchData({ ...searchData, [name]: e.target.value })
    }

    return (
        <Grid container md={12}>
            <Grid container md={12} className={`${classes.mb15}`}>
                <Grid item md={6}>
                    <Typography
                        className={`${classes.floatLeft} ${classes.title1}`}
                    >Secondary Criteria Details</Typography>
                </Grid>
                <Grid item md={6} lg={6} sm={6} xs={6}>
                    <RACButton
                        color="primary"
                        variant="contained"
                        size="small"
                        className={`${classes.floatRight}`}
                        onClick={() => showComp.grid && setShowComp({ ...showComp, filter: !(showComp.filter) })}
                    >
                        <RacFilter />
                        <span style={{ margin: "0px 8px" }}>Filter</span>
                        {/* Need to add filter icon !important */}
                        {showComp.filter ? <AccordianOpen /> : <AccordianClose />}
                    </RACButton>
                </Grid>
            </Grid>
            {showComp.filter ? <Grid container md={12}>
                <Card className={classes.card} style={{ overflow: "visible" }}>
                    <CardContent style={{ overflow: "visible" }}>
                        <Grid container md={12} spacing={2}>
                            <Grid item md={2} lg={2} sm={4} xs={6}>
                                <Typography>
                                    Zone<span style={{ color: "red" }}>*</span>
                                </Typography>
                                <MultiSelect
                                    isLoading={loader.dropDownLoader}
                                    options={dropDownSetter(dropdownValues.zone, 'zone')}
                                    className={`${classes.demo}  ${classes.prcingzoneMultiSelect} `}
                                    value={zoneSelected}
                                    labelledBy={"Select"}
                                    hasSelectAll={true}
                                    onChange={(e: any) => {
                                        setZoneSelected(e);
                                        let arr = e.map((item: any) => {
                                            if (item.value == item.value) return item.value;
                                        });
                                        setSearchData({ ...searchData, zone: (arr.length != dropdownValues.zone.length) ? arr : undefined })
                                    }}
                                />
                            </Grid>
                            <Grid item md={2} lg={2} sm={4} xs={6}>
                                <RACSelect
                                    name="department"
                                    inputLabel="Department"
                                    loading={loader.dropDownLoader}
                                    options={dropDownSetter(dropdownValues.department, 'department')}
                                    defaultValue={searchData?.department}
                                    onChange={(e) => {
                                        setSearchData({
                                            ...searchData,
                                            department: e.target.value,
                                            subdepartment: '',
                                            bracket: ''
                                        })
                                        const dataArray: any = [];
                                        let subDept = dropdownValues.subdepartment?.filter((obj: any) => {
                                            if (obj.departmentNumber == e.target.value) {
                                                return obj;
                                            }
                                        });
                                        if (subDept.length > 0) {
                                            subDept.map((item: any) => {
                                                dataArray.push({
                                                    label: item?.descEn,
                                                    value: (item?.subdepartmentNumber)?.toString(),
                                                    departmentNumber: item.departmentNumber
                                                })
                                            })
                                            dataArray.sort((a: any, b: any) => a.label?.localeCompare(b.label))
                                            dataArray.unshift({
                                                label: "Select",
                                                value: "",
                                                departmentNumber: ''
                                            })
                                            setSubDeptOptions(dataArray);
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item md={2} lg={2} sm={4} xs={6}>
                                <RACSelect
                                    name="subdepartment"
                                    inputLabel="Sub Department"
                                    loading={loader.dropDownLoader}
                                    isDisabled={searchData.department ? false : true}
                                    options={subDeptOptions}
                                    defaultValue={searchData?.subdepartment}
                                    onChange={(e) => {
                                        setSearchData({
                                            ...searchData,
                                            subdepartment: e.target.value,
                                            bracket: ''
                                        })
                                        const dataArray: any = [];
                                        let bracket = dropdownValues.bracket?.filter((obj: any) => {
                                            if (obj.subdepartmentNumber == e.target.value) {
                                                return obj;
                                            }
                                        });
                                        if (bracket.length > 0) {
                                            bracket.map((item: any) => {
                                                dataArray.push({
                                                    label: item?.descEn,
                                                    value: (item?.bracketNumber)?.toString(),
                                                    subdepartmentNumber: item.subdepartmentNumber
                                                })
                                            })
                                            dataArray.sort((a: any, b: any) => a.label?.localeCompare(b.label))
                                            dataArray.unshift({
                                                label: "Select",
                                                value: "",
                                                subdepartmentNumber: ''
                                            })
                                            setBracketOptions(dataArray);
                                        }
                                    }
                                    }
                                />
                            </Grid>
                            <Grid item md={2} lg={2} sm={4} xs={6}>
                                <RACSelect
                                    name="bracket"
                                    inputLabel="Bracket"
                                    loading={loader.dropDownLoader}
                                    isDisabled={searchData.subdepartment ? false : true}
                                    options={bracketOptions}
                                    defaultValue={searchData?.bracket}
                                    onChange={(e) => {
                                        onChangeSearchFields(e, 'bracket')
                                    }}
                                />
                            </Grid>
                            <Grid item md={2} lg={2} sm={4} xs={6}>
                                <RACTextbox
                                    type="text"
                                    name="brand"
                                    inputlabel="Brand"
                                    maxlength={30}
                                    value={searchData?.brand}
                                    OnChange={(e: any) => {
                                        onChangeSearchFields(e, 'brand')
                                    }}
                                />
                            </Grid>
                            <Grid item md={2} lg={2} sm={4} xs={6}>
                                <RACTextbox
                                    type="text"
                                    name="rms"
                                    inputlabel="RMS #"
                                    maxlength={30}
                                    value={searchData?.rms}
                                    OnChange={(e: any) => {
                                        onChangeSearchFields(e, 'rms')
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container md={12} spacing={2} style={{ marginTop: '10px' }}>
                            <Grid item md={2} lg={2} sm={4} xs={6}>
                                <RACSelect
                                    name="ruleType"
                                    inputLabel="Rule type"
                                    loading={loader.dropDownLoader}
                                    options={dropDownSetter(dropdownValues.ruleType, 'ruleType')}
                                    defaultValue={searchData?.ruleType}
                                    onChange={(e) => {
                                        onChangeSearchFields(e, 'ruleType')
                                    }}
                                />
                            </Grid>
                            <Grid item md={2} lg={2} sm={4} xs={6}>
                                <RACSelect
                                    name="secondaryCriteriaType"
                                    inputLabel="Secondary Criteria type"
                                    loading={loader.dropDownLoader}
                                    options={dropDownSetter(dropdownValues.secondaryCriteria, 'secondaryCriteriaType')}
                                    defaultValue={searchData?.secondaryCriteriaType}
                                    onChange={(e) => {
                                        onChangeSearchFields(e, 'secondaryCriteriaType')
                                    }}
                                />
                            </Grid>
                            <Grid item md={2} lg={2} sm={4} xs={6}>
                                <RACSelect
                                    name="priceParameter"
                                    inputLabel="Price Parameter"
                                    loading={loader.dropDownLoader}
                                    options={dropDownSetter(dropdownValues.pricingParams, 'priceParameter')}
                                    defaultValue={searchData?.priceParameter}
                                    onChange={(e) => {
                                        onChangeSearchFields(e, 'priceParameter')
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container md={12}>
                            <Grid item md={12} lg={12} sm={12} xs={12}
                            >
                                <RACButton
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    disabled={zoneSelected.length == 0}
                                    className={`${classes.floatRight}`}
                                    onClick={() => {
                                        searchSecondaryCriteriaDetails()
                                    }}
                                >
                                    Apply
                                </RACButton>
                                <RACButton
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    className={`${classes.floatRight} ${classes.mr10}`}
                                    disabled={clearButtonDisable ? false : true}
                                    onClick={() => {
                                        setZoneSelected([]);
                                        setSecondaryCriteriaSearchContent([]);
                                        setShowComp({ ...showComp, grid: false });
                                        setSearchData({
                                            ...searchData,
                                            department: '',
                                            subdepartment: '',
                                            bracket: '',
                                            zone: '',
                                            ruleType: '',
                                            secondaryCriteriaType: '',
                                            priceParameter: '',
                                            brand: '',
                                            rms: ''
                                        })
                                    }}
                                >
                                    Clear
                                </RACButton>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid> : null}
        </Grid>
    );
}
