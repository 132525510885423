/* eslint-disable */
import { CardContent, Card, Grid, RACTable, Typography, RACTableCell, RACTableRow,CircularProgress, } from "@rentacenter/racstrap";
import { SecondaryCriteriaStyle } from "../Styles/SecondaryCriteria";
import { useContext } from "react";
import { CriteriaContext } from "./CriteriaContext";
import ReactPaginate from "react-paginate";
import { ReactComponent as EditIcon } from "../../../assets/images/Edit Icon.svg";
import { ReactComponent as SortIconDown } from "../../../assets/images/sort-icon.svg";
import { ReactComponent as SortIconUp } from "../../../assets/images/sort-icon-up.svg";
import moment from "moment";

export default function SecondaryCriteriaGrid() {
    const classes = SecondaryCriteriaStyle();
    const { gridHeaders, secondaryCriteriaSearchContent, setCriteriaDetailsForm,
        totalRowCount, pageNumber, setPageNumber, pageCount,searchSecondaryCriteriaDetails,
        showComp, setShowComp, sortArray, setBackupRecords,
     setEditCriteria, loader
     } = useContext(CriteriaContext);

    const scrollTop = (ref: any) => {
        if (ref) {
            const element = ref;
            const clientWidth = element.getBoundingClientRect().height;
            const negCal = clientWidth * 2;
            const negVal = clientWidth - negCal;
            window.scrollBy(0, negVal);
        }
    };
    const handlePageClick = (event: any) => {
        setPageNumber(event.selected);
        searchSecondaryCriteriaDetails((event.selected).toString());
      };

    const renderSecondaryCriteriaTableHeader = () => (
        <>
            {gridHeaders.map((item: any, index: any) => {
                return (
                 <RACTableCell>
                                  {" "}
                                  {item.isSortable == true ? (
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        sortArray(
                                          gridHeaders[index].key,
                                          index,
                                          gridHeaders,
                                          secondaryCriteriaSearchContent,
                                          (item.key =='endDate' || item.key =='startDate' ) ? true : false,
                                          item.key =='subdepartmentName' ? 'subdepartmentName' :''
                                        );
                                      }}
                                    >
                                      {" "}
                                      {item.heading + " "}{" "}
                                      {item.isSorted == true ? (
                                        item.sortDirection == "DESC" ? (
                                          <SortIconUp className={classes.ml2} />
                                        ) : (
                                          <SortIconDown className={classes.ml2} />
                                        )
                                      ) : null}
                                    </a>
                                  ) : (
                                    item.heading + " "
                                  )}{" "}
                </RACTableCell>);
            })}
        </>
    );
    const editRow =(obj : any)=>{
        const {zoneNumber, departmentNumber, subdepartmentNumber,bracketNumber,rmsItemNumber,brandName, 
            ruleApplicationOrder, criteriaEffectType,tableData, ruleAppRefCode, pricingParamRefCode, secondaryCriteriaRefCode,
            categoryData, equationData, startDate, endDate  } = obj
            let equation = null
            if(equationData){
                const param = equationData.equationDetails ?? null
                equation ={
                    equationType : equationData.equationRefCode,
                    parameters: param?.map((item : any)=>{
                        return {
                            equationParamKey : item.equationParamRefCode,
                            discountValue : (item.equationParamValue)?.toString()
                        }
                    })
                }
            }
            let category = null;
            if(categoryData){
                category ={
                    categoryType : categoryData.categoryRefCode,
                    categorySubType: categoryData.subCategoryRefCode,
                    discountValue : categoryData.effectValue?.toString()
                }

            }
            let range = null;
            if(tableData){
                range = tableData.map((item: any)=>{
                    return {
                        startRange : item.startRange.toString(),
                        endRange : item.endRange.toString(),
                        discountValue : item.discountValue.toString()
                    }
                })
            }
            const addPricingQueueObj ={
                zone: [zoneNumber.toString()],
                departmentNumber: departmentNumber?.toString() ?? '',
                subdepartmentNumber: subdepartmentNumber?.toString() ?? '',
                bracketNumber: bracketNumber?.toString() ?? '',
                rmsItemNumber: rmsItemNumber?.toString() ?? '',
                brandName: brandName ?? '',
                ruleType: ruleAppRefCode.toUpperCase(),
                applicationOrder: ruleApplicationOrder.toString(),
                secondaryCriteriaType: secondaryCriteriaRefCode.toUpperCase(),
                pricingParameterKey: pricingParamRefCode,
                effectType: criteriaEffectType.toUpperCase(),
                equation: equation ?? null,
                category: category ?? null,
                range : range ?? null,
                startDate: startDate,
                endDate: endDate
            }
            setCriteriaDetailsForm(JSON.parse(JSON.stringify(addPricingQueueObj)));
            setBackupRecords(JSON.parse(JSON.stringify(addPricingQueueObj)))
        

         setShowComp({...showComp, form: true});
        setEditCriteria(true)
    }


    const renderSecondaryCriteriaTableBody = () => (
        <>
            {loader. grid ? (
                <RACTableRow>
                    <Grid style={{position: 'absolute',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        left: '50%'}}>
                               <Grid className={classes.textCenter} style={{width : '100%'}}>
                                 <Grid className={classes.centerAlignImage}>
                                 <CircularProgress />
                                 </Grid>
                               </Grid>
                             </Grid>
                </RACTableRow>
            ) : (secondaryCriteriaSearchContent && secondaryCriteriaSearchContent.length > 0)?
            secondaryCriteriaSearchContent.map((obj: any, index: any) => {
                return (
                    <RACTableRow key={index} style={{backgroundColor: 'white'}}>
                        <RACTableCell>
                            {obj?.zoneNumber || '-'}
                        </RACTableCell>
                        <RACTableCell>
                            {obj?.departmentName || '-'}
                        </RACTableCell>
                        <RACTableCell>
                            {obj?.subdepartmentName || '-'}
                        </RACTableCell>
                        <RACTableCell>
                            {obj?.bracketName || '-'}
                        </RACTableCell>
                        <RACTableCell>
                            {obj?.brandName || '-'}
                        </RACTableCell>
                        <RACTableCell>
                            {obj?.rmsItemNumber || '-'}
                        </RACTableCell>
                        <RACTableCell>
                            {obj?.ruleApplicationType || '-'}
                        </RACTableCell>
                        <RACTableCell>
                            {obj?.secondaryCriteriaType || '-'}
                        </RACTableCell>
                        <RACTableCell>
                            {obj?.priceParameter || '-'}
                        </RACTableCell>
                        <RACTableCell>
                            {obj?.startDate && moment(obj?.startDate).format("MM/DD/YYYY")  || '-'}
                        </RACTableCell>
                        <RACTableCell>
                            {obj?.endDate && moment(obj?.endDate).format("MM/DD/YYYY")  || '-'}
                        </RACTableCell>
                        <RACTableCell style={{
                        width: "42px",
                        height: "45px",
                        cursor: "pointer",
                      }} >
                           <EditIcon onClick={()=>editRow(obj)}/>
                        </RACTableCell>
                    </RACTableRow>
                );
            }) : secondaryCriteriaSearchContent && secondaryCriteriaSearchContent.length== 0 ? 
            <RACTableRow>
                    <Grid style={{position: 'absolute',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        left: '50%'}}>
                               <Grid className={classes.textCenter} style={{width : '100%'}}>
                                 <Grid className={classes.centerAlignImage}>
                                 No Records Found !
                                 </Grid>
                               </Grid>
                             </Grid>
            </RACTableRow>
            : null}
        </>
    );  

    return (
        <>
            <Card ref={scrollTop} style={{ borderRadius: '12px', position: "relative", marginBottom: '4%', marginTop: '20px' }}>
                <CardContent style={{ paddingBottom: '0px' }}>
                    <Grid
                        style={{
                            width: "100%",
                            overflowX: "auto",
                            maxHeight: "63vh",
                            minHeight: loader. grid  ||  (secondaryCriteriaSearchContent && secondaryCriteriaSearchContent.length == 0)? '38vh' : '61vh'

                        }}
                    >
                        <RACTable
                            renderTableContent={renderSecondaryCriteriaTableBody}
                            renderTableHead={renderSecondaryCriteriaTableHeader}
                            style={{
                                width: "100%",
                                whiteSpace: "nowrap",
                            }}
                            className={classes.gridStyle}
                        />
                    </Grid>
                    {totalRowCount ? (<Grid className={` ${classes.textRight}`}>
                        <Grid className={`${classes.floatLeft}`}>

                            <Typography>
                                # of Records:&nbsp;
                                {pageNumber == 0
                                    ? totalRowCount < 25 ? totalRowCount : 25 * 1
                                    : (pageNumber + 1) == pageCount
                                        ? totalRowCount
                                        : (pageNumber + 1) * 25}
                                /{totalRowCount}
                            </Typography>
                        </Grid>

                        <ReactPaginate
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageCount={pageCount}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={0}
                            disableInitialCallback={true}
                            previousLabel="<"
                            breakLabel=""
                            initialPage={pageNumber}
                            pageClassName={classes.pagination}
                            pageLinkClassName={classes.pagelink}
                            previousLinkClassName={
                                pageNumber === 0
                                    ? classes.paginationprevnextdisable
                                    : classes.paginationprevnext
                            }
                            nextLinkClassName={
                                pageNumber === pageCount - 1
                                    ? classes.paginationprevnextdisable
                                    : classes.paginationprevnext
                            }
                            breakClassName={classes.pagination}
                            breakLinkClassName={classes.pagination}
                            containerClassName={classes.pagination}
                            activeClassName={classes.pageactive}
                            forcePage={pageNumber === 0 ? 0 : undefined}
                        />

                    </Grid>) : null}
                </CardContent>
            </Card>
        </>
    )
}