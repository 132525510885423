/* eslint-disable */
export interface OtherHierarchyLevel {
  departmentNumber: string | null;
  subdepartmentNumber: string | null;
  bracketNumber: string | null;
  rmsItemNumber: string | null;
}

interface TransformedItem {
  zone: string;
  rmsItemNumber?: string[];
  otherHierarchyLevel?: {
    departmentNumber?: string | null;
    subdepartmentNumber?: string | null;
    bracketNumber?: string | null;
  }[];
}
export interface PricingDetail {
  pricingType: string;
  rmsItemNumber: number | null;
  zoneNumber: number;
  purchaseCost: number | null;
  rmsItemDescription: string | null;
  departmentName: string;
  departmentNumber: number;
  subdepartmentName: string | null;
  subDepartmentNumber: number | null;
  bracketName: string | null;
  bracketNumber: number | null;
  brandName: string | null;
  modelNumber: string | null;
  eaFlag: string;
  weeklyRateNew?: string;
  weeklyRateUsed?: string;
  term?: string;
  cashPriceFactor: string | null;
  weeklyRateAddOn: string | null;
  forcedCashPrice: number | null;
  manualPoTurns: number | null;
  startDate: string;
  endDate: string;
  pricingParamValueIds: string[];
  totalCostNew: string | null;
  totalCostUsed: string | null;
  cashPriceNew: string | null;
  cashPriceUsed: string | null;
  totalRtoTurn: number | null;
  cashPriceTurn: number | null;
  attachmentUrl: string | null;
  sacDays: string | null;
  sacDaysPrinted: number | null;
  epoPercent: number | null;
  clearance: string | null;
}
export interface DropDownDetails {
  label: string;
  value: string;
  subdepartmentnumber: string;
}
export interface FormValidationObject {
  isOpen: boolean;
  isError: boolean;
  message: string;
}

// Interface for the hierarchy level in the output
interface HierarchyLevel {
  departmentNumber: string | null;
  subdepartmentNumber: string | null;
  bracketNumber: string | null;
}

export interface PricingItem {
  pricingType: string;
  rmsItemNumber: number | null;
  zoneNumber: number;
  purchaseCost: number | null;
  rmsItemDescription: string | null;
  departmentName: string;
  departmentNumber: number;
  subdepartmentName: string | null;
  subDepartmentNumber: number | null;
  bracketName: string | null;
  bracketNumber: number | null;
  brandName: string | null;
  modelNumber: string | null;
  eaFlag: string;
  cashPriceFactor: number | null;
  weeklyRateAddOn: string;
  forcedCashPrice: number | null;
  manualPoTurns: number | null;
  startDate: string;
  endDate: string;
  pricingParamValueIds: string[];
  totalCostNew: number | null;
  totalCostUsed: number | null;
  cashPriceNew: number | null;
  cashPriceUsed: number | null;
  totalRtoTurn: number | null;
  cashPriceTurn: number | null;
  attachmentUrl: string | null;
}

// Type for the function
type TransformFunction = (inputData: PricingDetail[]) => TransformedItem[];
export function handleFormInputData(
  zoneNumbers: number[],
  otherHierarchyLevelArr: OtherHierarchyLevel
): TransformedItem[] {
  return zoneNumbers.map((zone) => {
    const transformedItem: TransformedItem = {
      zone: zone.toString(),
    };

    if (otherHierarchyLevelArr.rmsItemNumber) {
      if (!Array.isArray(transformedItem.rmsItemNumber))
        transformedItem.rmsItemNumber = [
          otherHierarchyLevelArr.rmsItemNumber.toString(),
        ];
      else if (transformedItem.rmsItemNumber)
        transformedItem.rmsItemNumber.push(
          otherHierarchyLevelArr.rmsItemNumber.toString()
        );
    } else if (
      otherHierarchyLevelArr.departmentNumber ||
      otherHierarchyLevelArr.subdepartmentNumber ||
      otherHierarchyLevelArr.bracketNumber
    ) {
      if (!Array.isArray(transformedItem.otherHierarchyLevel)) {
        transformedItem.otherHierarchyLevel = [
          {
            departmentNumber: otherHierarchyLevelArr.departmentNumber
              ? otherHierarchyLevelArr.departmentNumber.toString()
              : null,
            subdepartmentNumber: otherHierarchyLevelArr.subdepartmentNumber
              ? otherHierarchyLevelArr.subdepartmentNumber.toString()
              : null,
            bracketNumber: otherHierarchyLevelArr.bracketNumber
              ? otherHierarchyLevelArr.bracketNumber.toString()
              : null,
          },
        ];
      } else if (transformedItem.otherHierarchyLevel) {
        transformedItem.otherHierarchyLevel.push({
          departmentNumber: otherHierarchyLevelArr.departmentNumber
            ? otherHierarchyLevelArr.departmentNumber.toString()
            : null,
          subdepartmentNumber: otherHierarchyLevelArr.subdepartmentNumber
            ? otherHierarchyLevelArr.subdepartmentNumber.toString()
            : null,
          bracketNumber: otherHierarchyLevelArr.bracketNumber
            ? otherHierarchyLevelArr.bracketNumber.toString()
            : null,
        });
      }
    }

    return transformedItem;
  });
}

// The function with type annotations
export const transformData: TransformFunction = (inputData: PricingDetail[]) => {
  const result: { [key: string]: TransformedItem } = {};

  inputData.forEach((item) => {
    const zone = item.zoneNumber.toString();
    if (!result[zone]) {
      result[zone] = {
        zone: zone,
      };
    }

    if (
      item.rmsItemNumber &&
      item.departmentNumber &&
      item.subDepartmentNumber &&
      item.bracketNumber
    ) {
      if (Object.prototype.hasOwnProperty.call(result[zone],'rmsItemNumber')) {
        if (
          !result[zone]?.rmsItemNumber?.includes(item.rmsItemNumber.toString())
        ) {
          result[zone]?.rmsItemNumber?.push(item.rmsItemNumber.toString());
        }
      } else {
        result[zone].rmsItemNumber = [item.rmsItemNumber.toString()];
      }
    } else if (
      item.departmentNumber ||
      item.subDepartmentNumber ||
      item.bracketNumber
    ) {
      const hierarchyItem: HierarchyLevel = {
        departmentNumber: item.departmentNumber
          ? item.departmentNumber.toString()
          : null,
        subdepartmentNumber: item.subDepartmentNumber
          ? item.subDepartmentNumber.toString()
          : null,
        bracketNumber: item.bracketNumber
          ? item.bracketNumber.toString()
          : null,
      };

      const exists = result[zone]?.otherHierarchyLevel?.some(
        (h) =>
          h.departmentNumber === hierarchyItem.departmentNumber &&
          h.subdepartmentNumber === hierarchyItem.subdepartmentNumber &&
          h.bracketNumber === hierarchyItem.bracketNumber
      );

      if (!exists) {
        if (Object.prototype.hasOwnProperty.call(result[zone],'rmsItemNumber')) {
          result[zone]?.otherHierarchyLevel?.push(hierarchyItem);
        } else {
          result[zone].otherHierarchyLevel = [hierarchyItem];
        }
      }
    }
  });

  return Object.values(result);
};
