/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  RACButton,
  RACDatePicker,
  RACModalCard,
  RACRadio,
  RACSelect,
  RACTextbox,
  Typography,
} from "@rentacenter/racstrap";
import { PricingDetailsProvider } from "./PricingContext";
import { MultiSelect } from "react-multi-select-component";
import { ProgressBar } from "../Styles/ProgressStyles";
import { ReactComponent as DangerIcon } from "../../../assets/images/danger.svg";
import { ReactComponent as SuccessIcon } from "../../../assets/images/success-icon.svg";
import { ReactComponent as WarningIcon } from "../../../assets/images/no-records-found.svg";
import moment from "moment";
import { ReactComponent as DefaultImage } from "../../../assets/images/Default Image.svg";
import { globalStyles } from "../Styles/globalstyles";
import { addPricingQueueAPI } from "../../../api/user";
import { DropDownDetails, FormValidationObject, handleFormInputData, OtherHierarchyLevel, transformData } from "../../shared/handleFormData";
export default function PricingFooter() {
  const classes = ProgressBar();
  const classes1 = globalStyles();
  const {
    isAttachmentOpen,
    setIsAttachmentOpen,
    zoneOptions, setFullloader, isApprovalOpen, setIsApprovalOpen,
    pricingGridData, isApproved, setIsApproved, isFranchiseeUser,
    isNewPricingOpen, companyCode, updatePricingQueueStatus,
    type,
    setLoader,
    pageNumber,
    setIsNewPricingOpen,
    selectedItemsArray,
    setRmsItemDetailsArr, approvedBy,
    setSelectedItemsArray, showPopup, setShowPopup,
    isUpdatePricingOpen,
    setIsUpdatePricingOpen,
    setPricingQueueId,
    formBackupData,
    setFormBackupData,
    addPricingRequestOject,
    addPricingReq,
    setAddPricingReq,
    someThingWentWrng, setAuthOpen,
    setSomeThingWentWrng,
    bracketOptionsOrg, deptOptions,
    subDeptOptionsOrg,
    formValidationObject, setFormValidationObject
  } = useContext(PricingDetailsProvider);
  const currentDate = moment(moment(new Date()).format("YYYY-MM-DD"),"YYYY-MM-DD");
  const [throwvalidation, setThrowValidation] = useState<string>('');
  const [isUpdateWarningOpen, setIsUpdateWarningOpen] = useState(false);
  const [zoneSelected, setZoneSelected] = useState([]);
  const hierarchyLevelObj: OtherHierarchyLevel = {
    departmentNumber: '',
    subdepartmentNumber: '',
    bracketNumber: '',
    rmsItemNumber: ''
  }
  const formRef = useRef<HTMLFormElement | HTMLDivElement | null>(null);
  const [hierarchyLevelSelected, sethierarchyLevelSelected] = useState<OtherHierarchyLevel>(hierarchyLevelObj);
  const [subDeptOptions, setsubDeptOptions] = useState<any>([{
    label: "Select",
    value: "",
    subdepartmentnumber: ''
  }]);
  const [bracketOptions, setbracketOptions] = useState<DropDownDetails[]>([{
    label: "Select",
    value: "",
    subdepartmentnumber: ''
  }]);
  const [addPriceQueueErrMSg, setAddPriceQueueErrMsg] = useState<string>('');
  const [actionType, setActionType] = useState<string>('');
  let imageSource: any;
  useEffect(() => {
    imageSource = `${pricingGridData
      ? pricingGridData[`${pageNumber}`][isAttachmentOpen]?.productImg
      : null
      }`;
  }, [isAttachmentOpen]);

  useEffect(() => {
    populateValue();
  }, [isUpdatePricingOpen]);
  useEffect(() => {
    subDeptOptionsOrg.length != subDeptOptions && setsubDeptOptions(subDeptOptionsOrg);
    bracketOptions.length != bracketOptionsOrg.length && setbracketOptions(bracketOptionsOrg);
    if (!isUpdatePricingOpen && isNewPricingOpen) {

      setFormValidationObject({
        ...formValidationObject,
        message: 'Please select the Type to get the required fields',
        isOpen: true,
        isError: false
      })
    }
    else if(isUpdatePricingOpen && !isNewPricingOpen){
      setFormValidationObject({
        ...formValidationObject,
        message: '',
        isOpen: false,
        isError: false
      })
    }
  }, [isNewPricingOpen, subDeptOptionsOrg, bracketOptionsOrg])
  const validateDatesRange = (input1: string, input2: string) => {
    const date1 = moment(input1, "YYYY-MM-DD");
    const date2 = moment(input2, "YYYY-MM-DD");
    if (date1.isAfter(date2) || date1.isSame(date2)) return true;
    else return false;
  };
  const validationPricingInputs = (obj: any) => {
    let pricingDetails = obj;
    if (obj.type == "TEMPORARY" && obj.endDate)
      if (obj.startDate)
        validateDatesRange(obj.startDate, obj.endDate);
      else {
        obj.startDate = `${moment(new Date()).format("YYYY-MM-DD")}`;
        validateDatesRange(obj.startDate, obj.endDate);
      }

    if (
      obj.type == "PERMANENT" ||
      obj.type == "TEMPORARY"
    ) {
      pricingDetails.manualPOTurns = "";
    }
    if (obj.type == 'MANUAL' && pricingDetails.term && (pricingDetails.weeklyRateNew != '' || pricingDetails.weeklyRateUsed != '')) //new condition
    {
      pricingDetails.term = '';
      pricingDetails.weeklyRateNew = '';
      pricingDetails.weeklyRateUsed = '';

    }
    if (obj.type == "PERMANENT" || obj.type == "MANUAL") // New condition
      pricingDetails.endDate = "";
    pricingDetails.totalCostNew = (
      pricingDetails?.weeklyRateNew * pricingDetails?.term
    ).toFixed(2);
    pricingDetails.cashPriceNew = pricingDetails?.forcedCashPrice ? pricingDetails.forcedCashPrice : (
      pricingDetails?.totalCostNew * pricingDetails?.cashPriceFactor
    ).toFixed(2);
    pricingDetails.totalCostUsed = (
      pricingDetails?.weeklyRateUsed * pricingDetails?.term
    ).toFixed(2);
    pricingDetails.cashPriceUsed = pricingDetails?.forcedCashPrice ? pricingDetails.forcedCashPrice : (
      pricingDetails?.totalCostUsed * pricingDetails?.cashPriceFactor
    ).toFixed(2);
    if (obj.type == "MANUAL")
      pricingDetails.weeklyRateAddOn = '';
    setAddPricingReq(pricingDetails);
    formValidation(pricingDetails)
    return true;
  };
  const formValidation = (obj: any, isError: boolean = false) => {
    let message: string = '';
    let isOpen: boolean = false;
    if ((obj.type == 'TEMPORARY' || obj.type == 'PERMANENT') && !(obj.forcedCashPrice || obj.cashPriceFactor || obj.term || obj.weeklyRateNew || obj.weeklyRateUsed || obj.weeklyRateAddOn || obj.epoPercent || (obj.sacDays && obj.sacDaysPrinted) || obj.clearance)) {
      message = 'Please provide at least one of these details to continue: Weekly Rate and Term, Cash Price, Add-On Rate, SAC Days, Clearance or EPO Percentage.'
      isOpen = true
    } else if (obj.type == "MANUAL" && !(obj.forcedCashPrice || obj.cashPriceFactor || obj.term || obj.weeklyRateNew || obj.weeklyRateUsed || obj.manualPOTurns || obj.epoPercent || (obj.sacDays && obj.sacDaysPrinted))) {
      message = 'Please provide at least one of these details to continue: Weekly Rate, Term, Cash Price, Manual PO-Turns, SAC Days or EPO Percentage.'
      isOpen = true
    }
    else if (!obj.type && !(isUpdatePricingOpen)) {
      isOpen = true
      message = 'Please select the Type to get the required fields.';
    }


    setFormValidationObject({ ...formValidationObject, isOpen: isOpen, message: message, isError: isError })

  }
  // Define the interface for the pricing object
  interface PricingData {
    pricingType: string;
    rmsItemNumber: number | null;
    zoneNumber: number;
    purchaseCost: number | null;
    rmsItemDescription: string | null;
    departmentName: string;
    departmentNumber: number;
    subdepartmentName: string | null;
    subDepartmentNumber: number | null;
    bracketName: string | null;
    bracketNumber: number | null;
    brandName: string | null;
    modelNumber: string | null;
    eaFlag: string;
    weeklyRateNew: string | null; // Assuming it can be null
    weeklyRateUsed: string | null; // Assuming it can be null
    term: string | null; // Assuming it can be null
    cashPriceFactor: string | null; // Assuming it can be null
    weeklyRateAddOn: string | null; // Assuming it can be null
    forcedCashPrice: string | null; // Assuming it can be null
    manualPoTurns: string | null; // Assuming it can be null
    startDate: string; // ISO format date as a string
    endDate: string; // ISO format date as a string
    pricingParamValueIds: string[];
    totalCostNew: number | null;
    totalCostUsed: number | null;
    cashPriceNew: number | null;
    cashPriceUsed: number | null;
    totalRtoTurn: number | null;
    cashPriceTurn: number | null;
    attachmentUrl: string | null;
    sacDays: string | null;
    sacDaysPrinted: string | null;
    epoPercent : string | null;
    clearance : string | null ;
  }

  // Define the parameters to check
  const pricingParameters = [
    'weeklyRateNew',
    'weeklyRateAddOn',
    'weeklyRateUsed',
    'term',
    'cashPriceFactor',
    'forcedCashPrice',
    'manualPoTurns',
    'clearance',
    'sacDays',
    'sacDaysPrinted',
    'epoPercent',
  ] as const; // Use 'as const' to make it a tuple of literal types

  // Define a type for the reference object based on pricing parameters
  type ReferenceType = Pick<PricingData, typeof pricingParameters[number]>;
  // Function to check if all pricing parameters are the same
  const arePricingParametersSame = (dataArray: PricingData[]): boolean => {
    if (dataArray.length === 0) return true; // If the array is empty, return true

    // Get the first object's pricing parameters as a reference
    const reference: ReferenceType = {} as ReferenceType;

    for (const param of pricingParameters) {
      reference[param] = dataArray[0][param];
    }

    // Compare each object's pricing parameters with the reference
    for (let i = 1; i < dataArray.length; i++) {
      for (const param of pricingParameters) {
        if (dataArray[i][param] !== reference[param]) {
          return false; // Return false if any parameter differs
        }
      }
    }

    return true; // All parameters are the same
  };

  const populateValue = async () => {

    if (selectedItemsArray.length > 0 && isUpdatePricingOpen) {
      const transformedData = await transformData(selectedItemsArray);

      setRmsItemDetailsArr(transformedData);
      const count = arePricingParametersSame(selectedItemsArray)
      if (count) {
        setAddPricingReq({
          ...addPricingReq,
          ["type"]: selectedItemsArray[0].pricingType,
          ["weeklyRateNew"]: selectedItemsArray[0].weeklyRateNew ? selectedItemsArray[0].weeklyRateNew : '',
          ["weeklyRateUsed"]: selectedItemsArray[0].weeklyRateUsed ? selectedItemsArray[0].weeklyRateUsed : '',
          ["term"]: selectedItemsArray[0].term ? selectedItemsArray[0].term : '',
          ["cashPriceFactor"]: selectedItemsArray[0].cashPriceFactor && selectedItemsArray[0].forcedCashPrice ? '' : selectedItemsArray[0].cashPriceFactor,
          ["forcedCashPrice"]: selectedItemsArray[0].cashPriceFactor && selectedItemsArray[0].forcedCashPrice ? '' : selectedItemsArray[0].forcedCashPrice ? selectedItemsArray[0].forcedCashPrice : '',
          ["manualPOTurns"]: selectedItemsArray[0].manualPoTurns ? selectedItemsArray[0].manualPoTurns : '',
          ["cashPriceNew"]: selectedItemsArray[0].cashPriceNew && !(isNaN(selectedItemsArray[0].cashPriceNew)) ? selectedItemsArray[0].cashPriceNew : '',
          ["totalCostNew"]: selectedItemsArray[0].totalCostNew && !(isNaN(selectedItemsArray[0].totalCostNew)) ? selectedItemsArray[0].totalCostNew : '',
          ["cashPriceUsed"]: selectedItemsArray[0].cashPriceUsed,
          ["totalCostUsed"]: selectedItemsArray[0].totalCostUsed,
          ["startDate"]: moment(
            new Date(selectedItemsArray[0].startDate)
          ).format("YYYY-MM-DD"),
          ["endDate"]: selectedItemsArray[0].pricingType?.toLowerCase() == 'permanent' ? '' : moment(new Date(selectedItemsArray[0].endDate)).format(
            "YYYY-MM-DD"
          ),
          ["queueType"]: selectedItemsArray[0].queueType,
          ["rmsItemDetails"]: transformedData,
          ['weeklyRateAddOn']: selectedItemsArray[0].weeklyRateAddOn ? selectedItemsArray[0].weeklyRateAddOn : '',
          ['clearance'] : selectedItemsArray[0].clearance != undefined || selectedItemsArray[0].clearance != null ? 
          (selectedItemsArray[0].clearance == 0 ? 'N' : 'Y')  : '',
          ['sacDays'] : selectedItemsArray[0].sacDays  ? selectedItemsArray[0].sacDays  : '',
          ['sacDaysPrinted'] : selectedItemsArray[0].sacDaysPrinted  ? selectedItemsArray[0].sacDaysPrinted  : '',
          ['epoPercent'] : selectedItemsArray[0].epoPercent  ? selectedItemsArray[0].epoPercent  : '',
        });
        setFormBackupData({
          ...addPricingReq,
          ["type"]: selectedItemsArray[0].pricingType,
          ["weeklyRateNew"]: selectedItemsArray[0].weeklyRateNew ? selectedItemsArray[0].weeklyRateNew : '',
          ["weeklyRateUsed"]: selectedItemsArray[0].weeklyRateUsed ? selectedItemsArray[0].weeklyRateUsed : '',
          ["term"]: selectedItemsArray[0].term ? selectedItemsArray[0].term : '',
          ["cashPriceFactor"]: selectedItemsArray[0].cashPriceFactor,
          ["forcedCashPrice"]: selectedItemsArray[0].forcedCashPrice ? selectedItemsArray[0].forcedCashPrice : '',
          ["manualPOTurns"]: selectedItemsArray[0].manualPoTurns ? selectedItemsArray[0].manualPoTurns : '',
          ["cashPriceNew"]: selectedItemsArray[0].cashPriceNew && !(isNaN(selectedItemsArray[0].cashPriceNew)) ? selectedItemsArray[0].cashPriceNew : '',
          ["totalCostNew"]: selectedItemsArray[0].totalCostNew && !(isNaN(selectedItemsArray[0].totalCostNew)) ? selectedItemsArray[0].totalCostNew : '',

          ["cashPriceUsed"]: selectedItemsArray[0].cashPriceUsed,
          ["totalCostUsed"]: selectedItemsArray[0].totalCostUsed,
          ["startDate"]: moment(
            new Date(selectedItemsArray[0].startDate)
          ).format("YYYY-MM-DD"),
          ["endDate"]: selectedItemsArray[0].pricingType?.toLowerCase() == 'permanent' ? '' : moment(new Date(selectedItemsArray[0].endDate)).format(
            "YYYY-MM-DD"
          ),
          ["queueType"]: selectedItemsArray[0].queueType,
          ["rmsItemDetails"]: transformedData,
          ['weeklyRateAddOn']: selectedItemsArray[0].weeklyRateAddOn ? selectedItemsArray[0].weeklyRateAddOn : '',
          ['clearance'] : selectedItemsArray[0].clearance != undefined || selectedItemsArray[0].clearance != null ? 
          (selectedItemsArray[0].clearance == 0 ? 'N' : 'Y')  : '',
          ['sacDays'] : selectedItemsArray[0].sacDays  ? selectedItemsArray[0].sacDays  : '',
          ['sacDaysPrinted'] : selectedItemsArray[0].sacDaysPrinted  ? selectedItemsArray[0].sacDaysPrinted  : '',
          ['epoPercent'] : selectedItemsArray[0].epoPercent  ? selectedItemsArray[0].epoPercent  : '',
        });
      } else {
        const message = selectedItemsArray[0].pricingType == 'MANUAL' ? 'Please provide any of this details to continue Weekly Rate or Cash Price or Manual PO-Turns' : 'Please provide any of this details to continue Weekly Rate or Cash Price, Add-On Rate, SAC Days or EPO Percentage.';
        setFormValidationObject({ ...formValidationObject, isOpen: true, isError: false, message: message })
        setAddPricingReq({
          ...addPricingRequestOject,
          ["rmsItemDetails"]: transformedData,
          ["type"]: selectedItemsArray[0].pricingType,
        });
        setFormBackupData({
          ...addPricingRequestOject,
          ["rmsItemDetails"]: transformedData,
          ["type"]: selectedItemsArray[0].pricingType,
        });
      }
    }
  };
  const attachmentsPopUp = () => {
    if (pricingGridData && isAttachmentOpen >= 0)
      return (
        <>
          <Grid container spacing={2} className={`${classes1.p3}`}>
            <Grid
              style={{ display: "flex", width: "100%", gap: "24px" }}
              alignItems="center"
            >
              <Grid style={{ height: "146px", width: "239px" }}>
                {imageSource ? (
                  <img
                    src={imageSource}
                    alt="Image not found"
                    width={"100%"}
                    height={"100%"}
                    style={{ objectFit: "contain" }}
                  />
                ) : (
                  <Grid>
                    <DefaultImage style={{ objectFit: "contain" }} />
                  </Grid>
                )}
              </Grid>
              <Grid container style={{ width: "100%" }} alignItems="center">
                <Grid sm={6} md={4} lg={2} className={`${classes1.mb4}`}>
                  <Typography style={{ display: "inline" }}>Zone:</Typography>{" "}
                  <Typography
                    style={{
                      display: "inline",
                      fontWeight: "bold",
                      fontFamily: "OpenSans-bold",
                    }}
                  >
                    {pricingGridData[pageNumber][isAttachmentOpen]?.zoneNumber
                      ? pricingGridData[pageNumber][isAttachmentOpen]
                        ?.zoneNumber
                      : "-"}
                  </Typography>
                </Grid>
                <Grid sm={6} md={4} lg={3} className={`${classes1.mb4}`}>
                  <Typography style={{ display: "inline" }}>
                    Department:
                  </Typography>{" "}
                  <Typography
                    style={{
                      display: "inline",
                      fontWeight: "bold",
                      fontFamily: "OpenSans-bold",
                    }}
                  >
                    {pricingGridData[pageNumber][isAttachmentOpen]
                      ?.departmentName
                      ? pricingGridData[pageNumber][isAttachmentOpen]
                        ?.departmentName
                      : "-"}
                  </Typography>
                </Grid>
                <Grid sm={6} md={4} lg={3} className={`${classes1.mb4}`}>
                  <Typography style={{ display: "inline" }}>
                    Sub Department:
                  </Typography>{" "}
                  <Typography
                    style={{
                      display: "inline",
                      fontWeight: "bold",
                      fontFamily: "OpenSans-bold",
                    }}
                  >
                    {pricingGridData[pageNumber][isAttachmentOpen]
                      ?.subdepartmentName
                      ? pricingGridData[pageNumber][isAttachmentOpen]
                        ?.subdepartmentName
                      : "-"}
                  </Typography>
                </Grid>
                <Grid sm={6} md={4} lg={3} className={`${classes1.mb4}`}>
                  <Typography style={{ display: "inline" }}>
                    Bracket:
                  </Typography>{" "}
                  <Typography
                    style={{
                      display: "inline",
                      fontWeight: "bold",
                      fontFamily: "OpenSans-bold",
                    }}
                  >
                    {pricingGridData[pageNumber][isAttachmentOpen]?.bracketName
                      ? pricingGridData[pageNumber][isAttachmentOpen]
                        ?.bracketName
                      : "-"}
                  </Typography>
                </Grid>
                <Grid sm={6} md={4} lg={2} className={`${classes1.mb4}`}>
                  <Typography style={{ display: "inline" }}>RMS#:</Typography>{" "}
                  <Typography
                    style={{
                      display: "inline",
                      fontWeight: "bold",
                      fontFamily: "OpenSans-bold",
                    }}
                  >
                    {pricingGridData[pageNumber][isAttachmentOpen]
                      ?.rmsItemNumber
                      ? pricingGridData[pageNumber][isAttachmentOpen]
                        ?.rmsItemNumber
                      : "-"}
                  </Typography>
                </Grid>
                <Grid sm={6} md={4} lg={3} className={`${classes1.mb3}`}>
                  <Typography style={{ display: "inline" }}>Brand:</Typography>{" "}
                  <Typography
                    style={{
                      display: "inline",
                      fontWeight: "bold",
                      fontFamily: "OpenSans-bold",
                    }}
                  >
                    {pricingGridData[pageNumber][isAttachmentOpen]?.brandName
                      ? pricingGridData[pageNumber][isAttachmentOpen]?.brandName
                      : "-"}
                  </Typography>
                </Grid>
                <Grid sm={6} md={4} lg={3} className={`${classes1.mb3}`}>
                  <Typography style={{ display: "inline" }}>
                    Model #:
                  </Typography>{" "}
                  <Typography
                    style={{
                      display: "inline",
                      fontWeight: "bold",
                      fontFamily: "OpenSans-bold",
                    }}
                  >
                    {pricingGridData[pageNumber][isAttachmentOpen]?.modelNumber
                      ? pricingGridData[pageNumber][isAttachmentOpen]
                        ?.modelNumber
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    else return <></>;
  };

  const onchangeInputfield = (key: any, e: any) => {
    let obj = JSON.parse(JSON.stringify(addPricingReq));
    const epoPercentRegex =/^(0(\.[0-9]{0,3})?|1(\.0{0,3})?)?$/;

    let typeArr = [
      "type",
      "zone",
      "queueType",
      "clearance",
    ];
    const wholeNumberArr =['term','sacDays','sacDaysPrinted']
    if (key == 'startDate' || key == 'endDate') {
      const InputTxt = e;
      const TempArr = InputTxt.split('-');
      const newDate = `${TempArr[1]}/${TempArr[2]}/${TempArr[0]}`;
      const format = moment(InputTxt).format('MM/DD/YYYY');
      const temp = InputTxt.replace(format);
      obj[key] = temp;
    }
    else if (typeArr.includes(key)) {
      e = e.replace(/^0+(?=\d)/, "");
      obj[key] = e;
    } else if (key == 'cashPriceFactor' || key == 'epoPercent') {
      e = e.replace(/^0+(?=\d)/, "");
      if (e == '.')
        e = e.replace('.', '0.');
      if (key == 'cashPriceFactor' && e.match(/^[1-9]/)) {
        e = e.replace(e, '');
      }
      else if (key == 'epoPercent' && !e.match(epoPercentRegex)){
        e = e.replace(e, '');
      }
      e = e.replace(/(\.\d*)\..*$/, "$1"); // Remove any subsequent decimal points after the first one
      e = e.replace(/[^\d.]+/, ""); // Remove all non-numeric characters except .

      e = e.replace(/^0*(\.)?(\.[0-9]{3})\d+?$/, "$1")
      obj[key] = e;

    } else {
      e = e.replace(/^0+(?=\d)/, "");
      if ( !wholeNumberArr.includes(key)) {
        e = e.replace(/^[^\d]*(\d+(\.\d*)?)?/, "$1"); // Remove any non-numeric characters at the beginning, allowing only one optional decimal point after at least one digit
        e = e.replace(/[^\d.]+/, ""); // Remove all non-numeric characters except .
        e = e.replace(/(\.\d*)\..*$/, "$1"); // Remove any subsequent decimal points after the first one
        e = e.replace(/(\.\d{3})\d+?$/, "");
      } else {
        e = e.replace(/[^\d]/g, "");
      }
      obj[key] = e;

    }
    validationPricingInputs(obj)
  };
  const managePricing = async (isRemovePricing: any = []) => {
    try {
      if (isRemovePricing.length || validationPricingInputs(addPricingReq)) {
        if (!isRemovePricing.length)
          setIsNewPricingOpen(!isNewPricingOpen);
        setIsUpdatePricingOpen(false);
        setFullloader(true);
        let pricingRequest = addPricingReq;
        let queueType = pricingRequest.queueType
          ? pricingRequest.queueType
          : "IMMEDIATE";
        let arr = Object.keys(addPricingReq);
        arr.forEach((item: any) => {
          if (pricingRequest[item] == "" || pricingRequest[item] == null)
            delete pricingRequest[item];
        });

        if (!isRemovePricing.length) {
          if (!isUpdatePricingOpen)
            pricingRequest.rmsItemDetails = handleFormInputData((zoneSelected.map((item: any) => item?.value)), hierarchyLevelSelected);
          const date1 = moment(pricingRequest.startDate, "YYYY-MM-DD");
          const date2 = moment(pricingRequest.endDate, "YYYY-MM-DD");
          const date3 = moment().add(1, "day").format("YYYY MM DD");
          if (pricingRequest.startDate) {
            if (date1.isBefore(date3))
              pricingRequest.startDate = `${pricingRequest.startDate} ${moment()
                .format("hh:mm:ss")
                .toString()}`;
            else
              pricingRequest.startDate = pricingRequest.startDate + " 00:00:00";
          }
          if (pricingRequest.endDate) {
            if (date2.isBefore(date3))
              pricingRequest.endDate =
                pricingRequest.endDate + ' ' +
                moment().format("hh:mm:ss").toString();
            else pricingRequest.endDate = pricingRequest.endDate + " 00:00:00";
          }
        }
        let manualP0Turns = pricingRequest['manualPOTurns']
        let deleteArr = [
          "queueType",
          "cashPriceNew",
          "totalCostNew",
          "cashPriceUsed",
          "totalCostUsed",
          'manualPOTurns'
        ];
        deleteArr.forEach((item) => delete pricingRequest[item]);
        pricingRequest['manualPoTurns'] = manualP0Turns;
        let req = {
          schedule: queueType,
          status: "WAITING",
          submittedBy: window.sessionStorage.getItem('userName'),
          type: isRemovePricing.length ? 'REMOVE' : "PRICING",
          companyCode: companyCode && !(isRemovePricing.length) ? companyCode[0] : undefined,
          queueData: {
            pricingDetails: !(isRemovePricing.length) ? pricingRequest : undefined,
            removePricingDetails: (isRemovePricing.length) ? isRemovePricing : undefined
          },
        };

        const response = await addPricingQueueAPI(req);

        if (response.status == 200 && response.data.pricingQueueId) {
          //dff
          setFormValidationObject({ ...formValidationObject, isOpen: false, message: '', isError: false })
          setSelectedItemsArray([]);
          if (pricingGridData && Object?.keys(pricingGridData)?.length)
            Object?.keys(pricingGridData)?.forEach((element: any, index: any) => {
              pricingGridData[element].forEach((ele: any) => { delete ele.isChecked })
            });

          setAddPricingReq(addPricingRequestOject);
          setZoneSelected([]);
          setPricingQueueId(response.data.pricingQueueId);
          if(isRemovePricing.length){
            setActionType('REMOVE')
          } else {
            setActionType('')
          }
          setIsApprovalOpen(true);
        } else if (response?.status == 400) {
          const errMsg = response?.data?.errors?.[0]?.error || '';
          setAddPriceQueueErrMsg(errMsg);
          setSomeThingWentWrng(true);
        } else {
          throw "Err";
        }
      }
    } catch (err) {
      console.log(err);
      setSomeThingWentWrng(true);
    } finally {
      setThrowValidation('');
      setFullloader(false);
      sethierarchyLevelSelected(hierarchyLevelObj)
    }
  };
  const pricingPopUp = () => {
    const startDate = addPricingReq.startDate && moment(addPricingReq.startDate, "YYYY-MM-DD");
    const endDate = addPricingReq.endDate && moment(addPricingReq.endDate ,"YYYY-MM-DD");
    return (
      <>
        <ValidationComp {...formValidationObject} />
        <Grid container md={12} spacing={4} style={{ margin: '0px' }} >
          {isNewPricingOpen && !isUpdatePricingOpen ? (
            <>
              <Grid item md={4} className={classes.multiSelect}>
                <Typography>
                  Zone<span style={{ color: "red" }}>*</span>
                </Typography>
                <MultiSelect
                  options={zoneOptions}
                  value={zoneSelected}
                  isLoading={zoneOptions?.length > 0 || (isFranchiseeUser && companyCode.length == 0) ? false : true}
                  onChange={(e: any) => {
                    let arr = e.map((item: any) => {
                      if (item.value == item.value) return item.value;
                    });
                    setZoneSelected(e);
                  }}
                  labelledBy={"Select"}
                />
              </Grid>
              <Grid item md={4} className={classes.multiSelect}>
                <Typography>
                  Department
                </Typography>
                <RACSelect
                  loading={deptOptions.length > 1 ? false : true}
                  options={deptOptions}
                  defaultValue={
                    hierarchyLevelSelected.departmentNumber
                      ? hierarchyLevelSelected.departmentNumber
                      : ""
                  }
                  onChange={(e) => {
                    sethierarchyLevelSelected({
                      ...hierarchyLevelSelected,
                      ["departmentNumber"]: e.target.value,
                      ["subdepartmentNumber"]: "",
                      ["bracketNumber"]: ""
                    });
                    let subDept = subDeptOptionsOrg.filter((obj: any) => {
                      if (obj.departnumber == e.target.value) {
                        return obj;
                      }
                    });
                    if (subDept.length == 0) {
                      subDept = subDeptOptionsOrg;
                    }
                    else {
                      subDept.sort((a: any, b: any) =>
                        a["descEn"] < b["descEn"] ? -1 : 1).unshift({
                          label: "Select",
                          value: "",
                          deptId: "",
                        });
                    }
                    setsubDeptOptions(subDept);
                  }}
                />
              </Grid>
              <Grid item md={4} className={classes.multiSelect}>
                <Typography>
                  Sub Department
                </Typography>
                <RACSelect
                  options={subDeptOptions}
                  loading={(subDeptOptions.length > 1) ? false : true}
                  defaultValue={
                    hierarchyLevelSelected.subdepartmentNumber
                      ? hierarchyLevelSelected.subdepartmentNumber
                      : ""
                  }
                  isDisabled={hierarchyLevelSelected.departmentNumber == ''}
                  onChange={(e) => {
                    sethierarchyLevelSelected({
                      ...hierarchyLevelSelected,
                      ["subdepartmentNumber"]: e.target.value,
                      ["bracketNumber"]: ""
                    });
                    let bracket: DropDownDetails[] = bracketOptionsOrg.filter((obj: any) => {
                      if (obj.subdepartmentnumber == e.target.value) {
                        return obj;
                      }
                    });
                    if (bracket.length == 0) {
                      bracket = subDeptOptionsOrg;
                    }
                    else {
                      bracket.sort((a: DropDownDetails, b: DropDownDetails) =>
                        a["label"] < b["label"] ? -1 : 1).unshift({
                          label: "Select",
                          value: "",
                          subdepartmentnumber: "",
                        });
                    }
                    setbracketOptions(bracket);
                  }}
                />
              </Grid>
              <Grid item md={4} className={classes.multiSelect}>
                <Typography>
                  Bracket
                </Typography>
                <RACSelect
                  options={bracketOptions}
                  loading={(bracketOptions.length > 1) ? false : true}
                  isDisabled={hierarchyLevelSelected.subdepartmentNumber == ''}
                  defaultValue={
                    hierarchyLevelSelected.bracketNumber
                      ? hierarchyLevelSelected.bracketNumber
                      : ""
                  }
                  onChange={(e) => {
                    sethierarchyLevelSelected({
                      ...hierarchyLevelSelected,
                      ["bracketNumber"]: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item md={4} className={classes.currencyInputStyle}>
                <RACTextbox
                  maxlength={20}
                  value={addPricingReq.rmsItemNumber}
                  name="RMS #"
                  inputlabel="RMS #"
                  placeHolder='Enter the RMS#'
                  OnChange={(e) => {
                    e.target.value = e.target.value.replace(/[^\d]/g, "");
                    sethierarchyLevelSelected({
                      ...hierarchyLevelSelected, 'rmsItemNumber': e.target.value
                    })
                  }}
                ></RACTextbox>
              </Grid>
            </>
          ) : null}
          <Grid item md={4} className={classes.racSelect}>
            {isNewPricingOpen && isUpdatePricingOpen ? (
              <RACSelect
                inputLabel="Type"
                defaultValue={addPricingReq.type ? addPricingReq.type : ""}
                options={type}
                isDisabled={true}
                onChange={(e) => onchangeInputfield("type", e.target.value)}
              />
            ) : (
              <RACSelect
                inputLabel="Type"
                required
                defaultValue={addPricingReq.type ? addPricingReq.type : ""}
                options={type}
                onChange={(e) => {
                  onchangeInputfield("type", e.target.value);
                }}
              />
            )}
          </Grid>
          {(hierarchyLevelSelected.rmsItemNumber && (hierarchyLevelSelected.departmentNumber || hierarchyLevelSelected.subdepartmentNumber || hierarchyLevelSelected.bracketNumber)) ? <Grid md={12}>
            <Typography style={{ margin: '0% 35%', fontSize: '0.70rem' }}>Once the RMS # is entered, the selected Dept, Sub Dept and Bracket will be ignored.</Typography>
          </Grid> : null}
          <Grid item md={4} className={classes.currencyInputStyle}>
            <RACTextbox
              value={addPricingReq.weeklyRateNew ? addPricingReq.weeklyRateNew : ''}
              disabled={
                addPricingReq.type == "MANUAL" && addPricingReq.term
                  ? true
                  : false
              }
              name="Weekly Rate New"
              required={((addPricingReq.type == "PERMANENT" || addPricingReq.type == "TEMPORARY") && (addPricingReq.weeklyRateNew || addPricingReq.weeklyRateUsed || addPricingReq.term)) ||
                (addPricingReq.type == "MANUAL" && (addPricingReq.weeklyRateNew || addPricingReq.weeklyRateUsed))
              }
              placeHolder='Enter Weekly Rate New'
              isCurrency={true}
              inputlabel="Weekly Rate New"
              OnChange={(e) =>
                onchangeInputfield("weeklyRateNew", e.target.value)
              }
            ></RACTextbox>
          </Grid>
          <Grid item md={4} className={classes.currencyInputStyle}>
            <RACTextbox
              value={addPricingReq.weeklyRateUsed ? addPricingReq.weeklyRateUsed : ''}
              name="Weekly Rate Used"
              required={((addPricingReq.type == "PERMANENT" || addPricingReq.type == "TEMPORARY") && (addPricingReq.weeklyRateNew || addPricingReq.term)
              ) || (addPricingReq.type == "MANUAL" && (addPricingReq.weeklyRateUsed || addPricingReq.weeklyRateNew || addPricingReq.weeklyRateUsed))}
              placeHolder='Enter Weekly Rate Used'
              disabled={
                addPricingReq.type == "MANUAL" && addPricingReq.term
                  ? true
                  : false
              }
              isCurrency={true}
              inputlabel="Weekly Rate Used"
              OnChange={(e) =>
                onchangeInputfield("weeklyRateUsed", e.target.value)
              }
            ></RACTextbox>
          </Grid>
          <Grid item md={4} className={classes.currencyInputStyle}>
            <RACTextbox
              value={addPricingReq.term ? addPricingReq.term : ''}
              required={((addPricingReq.type == "PERMANENT" || addPricingReq.type == "TEMPORARY") && (addPricingReq.term || addPricingReq.weeklyRateNew || addPricingReq.weeklyRateUsed)) || (addPricingReq.type == "MANUAL" && addPricingReq.term)}
              placeHolder={'Enter the term'}
              disabled={
                addPricingReq.type == "MANUAL" &&
                  (addPricingReq.weeklyRateNew || addPricingReq.weeklyRateUsed)
                  ? true
                  : false
              }
              name="Term"
              inputlabel="Term"
              OnChange={(e) => onchangeInputfield("term", e.target.value)}
            ></RACTextbox>
          </Grid>
          <Grid item md={4} className={classes.currencyInputStyle}>
            <RACTextbox
              value={addPricingReq.weeklyRateAddOn ? addPricingReq.weeklyRateAddOn : ''}
              name="Add-On Rate"
              isCurrency={true}
              maxlength={10}
              inputlabel="Add-On Rate"
              placeHolder={'Enter Add-On Rate'}
              OnChange={(e) =>
                onchangeInputfield("weeklyRateAddOn", e.target.value)
              }
              disabled={addPricingReq.type == "MANUAL"}
            ></RACTextbox>
          </Grid>
          <Grid item md={4} className={classes.currencyInputStyle}>
            <RACTextbox
              value={addPricingReq.cashPriceFactor ? addPricingReq.cashPriceFactor : ''}
              name="Cash Price Factor"
              inputlabel="Cash Price Factor"
              placeHolder={'Enter the Cash Price Factor'}
              disabled={addPricingReq.forcedCashPrice ? true : false}
              OnChange={(e) => {
                onchangeInputfield("cashPriceFactor", e.target.value)
              }
              }
            ></RACTextbox>
          </Grid>
          <Grid item md={4} className={classes.currencyInputStyle}>
            <RACTextbox
              value={addPricingReq.forcedCashPrice ? addPricingReq.forcedCashPrice : ''}
              name="Forced Cash Price"
              disabled={addPricingReq.cashPriceFactor ? true : false}
              inputlabel="Forced Cash Price"
              placeHolder={'Enter the Forced Cash Price'}
              OnChange={(e) =>
                onchangeInputfield("forcedCashPrice", e.target.value)
              }
            ></RACTextbox>
          </Grid>
          <Grid item md={4} className={classes.currencyInputStyle}>
            <RACTextbox
              value={addPricingReq.manualPOTurns}
              name="Manual Po-Turns"
              placeHolder={'Enter the Manual PO-Turns'}
              disabled={addPricingReq.type == "MANUAL" ? false : true}
              // required={addPricingReq.type == "MANUAL"}
              inputlabel="Manual PO-Turns"
              OnChange={(e) =>
                onchangeInputfield("manualPOTurns", e.target.value)
              }
            ></RACTextbox>
          </Grid>
          <Grid item md={4} className={`${classes.overrideDisbaleInCurrencyInp}  `}>
            <RACTextbox
              value={addPricingReq.cashPriceNew}
              name="Cash Price New"
              inputlabel="Cash Price New"
              isCurrency={true}
              disabled={true}
              className={classes.currencyTextBoxBlue}
              OnChange={(e) =>
                onchangeInputfield("cashPriceNew", e.target.value)
              }
            ></RACTextbox>
          </Grid>
          <Grid item md={4} className={classes.overrideDisbaleInCurrencyInp}>
            <RACTextbox
              value={addPricingReq.totalCostNew}
              name="Total Cost New"
              inputlabel="Total Cost New"
              isCurrency={true}
              disabled={true}
              className={classes.currencyTextBoxBlue}
              OnChange={(e) =>
                onchangeInputfield("totalCostNew", e.target.value)
              }
            ></RACTextbox>
          </Grid>
          <Grid item md={4} className={classes.overrideDisbaleInCurrencyInp}>
            <RACTextbox
              value={addPricingReq.cashPriceUsed}
              name="Cash Price Used"
              inputlabel="Cash Price Used"
              isCurrency={true}
              disabled={true}
              className={classes.currencyTextBoxBlue}
              OnChange={(e) =>
                onchangeInputfield("cashPriceUsed", e.target.value)
              }
            ></RACTextbox>
          </Grid>
          <Grid item md={4} className={classes.overrideDisbaleInCurrencyInp}>
            <RACTextbox
              value={addPricingReq.totalCostUsed}
              name="Total Cost Used"
              inputlabel="Total Cost Used"
              isCurrency={true}
              disabled={true}
              className={classes.currencyTextBoxBlue}
            ></RACTextbox>
          </Grid>
          <Grid item md={4}>
          <RACTextbox
              value={addPricingReq.sacDays ? addPricingReq.sacDays : ''}
              name="SAC Days"
              inputlabel="SAC Days"
              placeHolder={'Enter the SAC Days'}
              maxlength={4}
              OnChange={(e) => onchangeInputfield("sacDays", e.target.value)}
            ></RACTextbox>
          </Grid>
          <Grid item md={4}>
          <RACTextbox
              value={addPricingReq.sacDaysPrinted ? addPricingReq.sacDaysPrinted : ''}
              name="SAC Days Printed"
              inputlabel="SAC Days Printed"
              placeHolder={'Enter the SAC Days Printed'}
              maxlength={4}
              OnChange={(e) => onchangeInputfield("sacDaysPrinted", e.target.value)}
            ></RACTextbox>
          </Grid>
          <Grid item md={4}>
          <RACTextbox
              value={addPricingReq.epoPercent ? addPricingReq.epoPercent : ''}
              name="EPO Percentage"
              inputlabel="EPO Percentage"
              placeHolder={'Enter the EPO Percentage'}
              OnChange={(e) => onchangeInputfield("epoPercent", e.target.value)}
          ></RACTextbox>
          </Grid>
          {(isNewPricingOpen && isUpdatePricingOpen) ? 
          <Grid item md={12} style={{padding: "0px 16px",display:'flex',justifyContent:'center'}}>
            {addPricingReq.sacDays && addPricingReq.sacDaysPrinted && (Number(addPricingReq.sacDaysPrinted) > Number(addPricingReq.sacDays)) ? <Grid md={4}>
            <Typography style={{ color:'red', fontSize: '0.70rem' }}>Enter 'SAC Days Printed' less than or Equal to 'SAC Days'.</Typography>
          </Grid> : null}
          </Grid>
          :null
          }
          <Grid item md={4} className={classes.currencyInputStyle}>
            {addPricingReq.endDate ? (
              <RACDatePicker
                label={"Start Date"}
                required
                inputProps={{
                  min: moment().format("YYYY-MM-DD"),
                  max: moment(addPricingReq.endDate)
                    .add(1, "day")
                    .format("YYYY-MM-DD"),
                }}
                value={addPricingReq.startDate}
                onChange={(e: any) => onchangeInputfield("startDate", e)}
              ></RACDatePicker>
            ) : (
              <RACDatePicker
                label={"Start Date"}
                required
                inputProps={{
                  min: moment().format("YYYY-MM-DD"),
                }}
                value={addPricingReq.startDate}
                onChange={(e: any) => onchangeInputfield("startDate", e)}
              ></RACDatePicker>
            )}
          </Grid>
          {isNewPricingOpen && !(isUpdatePricingOpen)?<Grid item md={12} style={{padding: "0px 16px",display: 'flex',justifyContent: `${(addPricingReq.sacDays && addPricingReq.sacDaysPrinted && (Number(addPricingReq.sacDaysPrinted) > Number(addPricingReq.sacDays)))? 'space-between' : 'flex-end'}`}}>
          {addPricingReq.sacDays && addPricingReq.sacDaysPrinted && (Number(addPricingReq.sacDaysPrinted) > Number(addPricingReq.sacDays)) ? <Grid md={4}>
            <Typography style={{ color:'red', fontSize: '0.70rem' }}>Enter 'SAC Days Printed' less than or Equal to 'SAC Days'.</Typography>
          </Grid> : null}
          {startDate && (startDate?.isBefore(currentDate))?<Grid md={4}>
          <Typography style={{ color:'red', fontSize: '0.70rem',display: 'flex',
    flexDirection: 'row-reverse'}}>Start Date should not be a past date.</Typography> 
          </Grid> : null}
          </Grid> : null}
          <Grid item md={4} className={classes.currencyInputStyle}>
            <RACDatePicker
              label={"End Date"}
              required={addPricingReq.type == 'TEMPORARY' ? true : false}
              disabled={addPricingReq.type == "PERMANENT" || addPricingReq.type == 'MANUAL' ? true : false}
              value={addPricingReq.type == "PERMANENT" || addPricingReq.type == 'MANUAL' ? "2099-12-31" : addPricingReq.endDate}
              inputProps={{
                min: moment().format("YYYY-MM-DD"),
              }}
              onChange={(e: any) => onchangeInputfield("endDate", e)}
            ></RACDatePicker>
          </Grid>
           <Grid item md={12} style={{padding: "10px 16px",display: 'flex',justifyContent:`${              (isNewPricingOpen && isUpdatePricingOpen && startDate && !(startDate?.isBefore(currentDate)))? 'center':'' }`}}>
            {
              (isNewPricingOpen && isUpdatePricingOpen && startDate && (startDate?.isBefore(currentDate))) ? <Grid md={4}>
              <Typography style={{ color:'red', fontSize: '0.70rem',}}>Start Date should not be a past date.</Typography> 
              </Grid>  : null
            }
           {endDate && (endDate?.isBefore(currentDate)) ? <Grid item md= {4} style={{padding:isNewPricingOpen && isUpdatePricingOpen? '0px 16px':'0px 0px'}}>
            <Typography style={{ color:'red', fontSize: '0.70rem',}}>End Date should not be a past date.</Typography> 
            </Grid> : (startDate && endDate && endDate?.isBefore(startDate))?<Grid item md= {4} style={{padding:'0px 16px'}}>
            <Typography style={{ color:'red', fontSize: '0.70rem',}}>End Date should be greater than or equal to start Date.</Typography> 
            </Grid> : null}
          </Grid> 
        </Grid>
        <Grid item md={12} xs={12} lg={12} sm={12} style={{ marginTop: "1%", marginLeft: '2%' }}>
          <Typography>Do you want to initiate Clearance? </Typography>
          <Grid
            style={{
              margin: "2%",
            }}
          >
            <RACRadio
              value={"Yes"}
              checked={
                addPricingReq?.clearance?.toLowerCase() == 'y' ? true : false
              }
              label="Yes"
              name="yes"
              onClick={() => onchangeInputfield("clearance", "Y")}
              disabled ={  addPricingReq.type == 'MANUAL' ? true : false}
            ></RACRadio>
            <RACRadio
              value={"no"}
              checked={
                addPricingReq?.clearance?.toLowerCase() == 'n' ? true : false
              }
              label="No"
              name="no"
              onClick={() => onchangeInputfield("clearance", "N")}
              disabled ={  addPricingReq.type == 'MANUAL' ? true : false}
            ></RACRadio>
          </Grid>
        </Grid>

        {/* {isNewPricingOpen && isUpdatePricingOpen ? */}
        <>
          <Grid spacing={4} style={{ marginTop: "1%", marginLeft: '2%' }}>
            <Typography> How do you want to update the pricing? <span style={{ color: 'red' }}>*</span></Typography>
            <Grid
              style={{
                margin: "1%",
              }}
            >
              <RACRadio
                value={""}
                checked={
                  addPricingReq.queueType == "IMMEDIATE" ? true : false
                }
                label="Immediately"
                name="updateType"
                onClick={() => onchangeInputfield("queueType", "IMMEDIATE")}
              ></RACRadio>
              <RACRadio
                value={""}
                checked={
                  addPricingReq.queueType == "OVERNIGHT" ? true : false
                }
                label="Overnight"
                name="updateType"
                onClick={() => onchangeInputfield("queueType", "OVERNIGHT")}
              ></RACRadio>
            </Grid>
            <Typography>
              <span style={{ fontFamily: "OpenSans-bold" }}>Note: </span>
              <span
                style={{
                  fontWeight: "lighter",
                  fontFamily: "OpenSans-regular",
                }}
              >
                The selected criteria matches with {selectedItemsArray.length}{" "}
                RMS record
              </span>
            </Typography>
          </Grid>
        </>
        {/* : null} */}

        <Grid
          style={{
            float: "right",
            width: "100%",
            display: "flex",
            flexDirection: "row-reverse",
            height: '40px'
          }}
          md={12}
        >
          <RACButton
            variant="contained"
            color="primary"
            size="small"
            disabled={disableNextButton()}

            style={{ float: "right", marginRight: "2%", /* marginTop:'2%' */ }}
            onClick={() => {
              if (addPricingReq.type != 'MANUAL' && (addPricingReq.weeklyRateAddOn && addPricingReq.weeklyRateNew && addPricingReq.weeklyRateUsed) && (Number(addPricingReq.weeklyRateAddOn) >= Number(addPricingReq.weeklyRateNew) || Number(addPricingReq.weeklyRateAddOn) >= Number(addPricingReq.weeklyRateUsed))) {
                setThrowValidation('Add-On Rate is greater than or equal to the weekly rate.')
                setSomeThingWentWrng(true);

              } else if (!(addPricingReq.weeklyRateAddOn || addPricingReq.weeklyRateNew || addPricingReq.weeklyRateUsed || addPricingReq.term || addPricingReq.cashPriceFactor || addPricingReq.forcedCashPrice || addPricingReq.manualPOTurns || (addPricingReq.sacDays && addPricingReq.sacDaysPrinted) || addPricingReq.clearance || addPricingReq.epoPercent)) {
                scrollToTop();
                formValidation(addPricingReq, true)
              }
              else {
                managePricing();
              }
            }}
          >
            Next
          </RACButton>
          <RACButton
            variant="outlined"
            color="primary"
            size="small"
            style={{ float: "right", marginRight: "2%", /* marginTop:'2%' */ }}
            onClick={() => {
              setFormValidationObject({ ...formValidationObject, isOpen: false, message: '', isError: false });
              setAddPricingReq(addPricingRequestOject);
              setIsNewPricingOpen(!isNewPricingOpen);
              setIsUpdatePricingOpen(false);
              setZoneSelected([]);
              sethierarchyLevelSelected(hierarchyLevelObj);
            }}
          >
            Cancel
          </RACButton>
          {isNewPricingOpen && isUpdatePricingOpen ? (
            <RACButton
              variant="text"
              color="primary"
              style={{ paddingRight: "1.25rem", marginTop: '3%', textDecoration: 'underline', textDecorationColor: '#0d6efd' }}
              className={
                (JSON.stringify(formBackupData) == JSON.stringify(addPricingReq)) && formBackupData.type
                  ? classes.buttonDisabled
                  : ""
              }
              onClick={() => {
                setAddPricingReq(JSON.parse(JSON.stringify(formBackupData)));
              }}
            >
              Set as Default
            </RACButton>
          ) : null}
        </Grid>
      </>
    );
  };
  const validatePricingDetails = () => {
    const startDate = addPricingReq.startDate && moment(addPricingReq.startDate, "YYYY-MM-DD");
    const endDate = addPricingReq.endDate && moment(addPricingReq.endDate, "YYYY-MM-DD")
    if(startDate && (startDate?.isBefore(currentDate)))
      return true;
    if(startDate && endDate && (endDate?.isBefore(startDate)))
      return true;
    /* When no pricing parameters are exist*/
    if (!((!(addPricingReq.sacDays && addPricingReq.sacDaysPrinted) || (addPricingReq.sacDays && addPricingReq.sacDaysPrinted)) || addPricingReq.weeklyRateAddOn || addPricingReq.weeklyRateNew || addPricingReq.weeklyRateUsed || addPricingReq.term || addPricingReq.cashPriceFactor || addPricingReq.forcedCashPrice || addPricingReq.manualPOTurns || addPricingReq.epoPercent || addPricingReq.clearance )) {
      return false
    }
    else if (addPricingReq.type != 'MANUAL') {
      if ((addPricingReq.term && addPricingReq.weeklyRateNew && addPricingReq.weeklyRateUsed) && ((addPricingReq.type == 'TEMPORARY' && addPricingReq.endDate) || (addPricingReq.type == 'PERMANENT' && (!addPricingReq.endDate)))
        && 
      !((addPricingReq.sacDays && !addPricingReq.sacDaysPrinted) || (!addPricingReq.sacDays && addPricingReq.sacDaysPrinted))
      && (!(addPricingReq.sacDays && addPricingReq.sacDaysPrinted) || (addPricingReq.sacDays && addPricingReq.sacDaysPrinted && (Number(addPricingReq.sacDaysPrinted) <= Number(addPricingReq.sacDays))))
      )
        return false
      else if ((addPricingReq.term || addPricingReq.weeklyRateNew || addPricingReq.weeklyRateUsed) || (addPricingReq.sacDays && !addPricingReq.sacDaysPrinted) || (!addPricingReq.sacDays && addPricingReq.sacDaysPrinted) || (addPricingReq.sacDays && addPricingReq.sacDaysPrinted && (Number(addPricingReq.sacDaysPrinted) > Number(addPricingReq.sacDays))))
        return true
      else if((addPricingReq.type == 'TEMPORARY' && addPricingReq.startDate && !addPricingReq.endDate && ((!(addPricingReq.sacDays && addPricingReq.sacDaysPrinted) || (addPricingReq.sacDays && addPricingReq.sacDaysPrinted)) || addPricingReq.weeklyRateAddOn || addPricingReq.weeklyRateNew || addPricingReq.weeklyRateUsed || addPricingReq.term || addPricingReq.cashPriceFactor || addPricingReq.forcedCashPrice || addPricingReq.manualPOTurns || addPricingReq.epoPercent || addPricingReq.clearance )))
        return true
      else
        return false
    }
    else {
      if (
        ((addPricingReq.term && !(addPricingReq.weeklyRateNew && addPricingReq.weeklyRateUsed)) || 
         (!addPricingReq.term && addPricingReq.weeklyRateNew && addPricingReq.weeklyRateUsed)) ||
        (!((addPricingReq.sacDays && !addPricingReq.sacDaysPrinted) || 
          (!addPricingReq.sacDays && addPricingReq.sacDaysPrinted)) &&
        (addPricingReq.sacDays && addPricingReq.sacDaysPrinted &&
         Number(addPricingReq.sacDaysPrinted) <= Number(addPricingReq.sacDays)))
    ) {
        return false;
    }
      else if (addPricingReq.weeklyRateNew || addPricingReq.weeklyRateUsed || (addPricingReq.sacDays && !addPricingReq.sacDaysPrinted) || (!addPricingReq.sacDays && addPricingReq.sacDaysPrinted) || (addPricingReq.sacDays && addPricingReq.sacDaysPrinted && (Number(addPricingReq.sacDaysPrinted) > Number(addPricingReq.sacDays))) )
        return true
      else
        return false
    }
  }

  const disableNextButton = (): boolean => {
    /* new pricing  */
    if ((isNewPricingOpen && !isUpdatePricingOpen && zoneSelected.length != 0 && Object.values(hierarchyLevelSelected).some(value => value !== '') && addPricingReq.type && addPricingReq.queueType && addPricingReq.startDate)) {
      return validatePricingDetails()
    }
    /* upate pricing */
    else if ((isNewPricingOpen && isUpdatePricingOpen && addPricingReq.type && addPricingReq.queueType && addPricingReq.startDate)) {
      return validatePricingDetails()
    }
    return true;
  }
  const updatingWarning = () => {
    return (
      <>
        <Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <DangerIcon />
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                padding: "5%",
              }}
            >
             { showPopup.cautionRemovePricing ? 'These records cannot be removed because you have selected records with a pricing type other than TEMPORARY':'These records cannot be edited, Since you have selected the records of more than one type.'}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };
  const approvalPopup = () => {
    return (
      <>
        <Grid>
          <Grid style={{ textAlign: "center", marginBottom: "2%" }}>
            <SuccessIcon />
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{ textAlign: "center", marginBottom: "2%" }}
            >
              {actionType == 'REMOVE' ? 'Removal' : ''} Request has been submitted.
            </Typography>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              Do you want to continue for approval?
            </Typography>
          </Grid>
          <Grid
            style={{ display: "flex", justifyContent: "center", margin: "5%" }}
          >
            <RACButton
              style={{ marginRight: "10%" }}
              variant="outlined"
              onClick={() => {
                setIsApprovalOpen(false);
              }}
            >
              No
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => {
                setIsApprovalOpen(false);
                setAuthOpen(true);
              }}
            >
              Yes
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };
  const someThingWentWrongFn = () => {
    return (
      <>
        <Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <WarningIcon width={"10%"} height={"10%"} />
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{
                textAlign: "center",
                padding: '2% 0%'
              }}
            >
              {addPriceQueueErrMSg ? addPriceQueueErrMSg : throwvalidation != '' ? throwvalidation : 'Something went Wrong.'}
            </Typography>
            {throwvalidation != '' && <Typography
              variant="h5"
              style={{
                textAlign: "center",
                padding: '0% 0% 2% 0%'
              }}
            >
              {'Do you still want to proceed?'}
            </Typography>}
          </Grid>
          <Grid
            style={{
              justifyContent: "center",
              display: "flex",
              marginTop: '5%'
            }}
          >
            {throwvalidation != '' ?
              <>
                <RACButton onClick={() => setSomeThingWentWrng(false)}
                  color="primary"
                  variant="outlined">
                  NO
                </RACButton>
                <RACButton onClick={() => {
                  throwvalidation && managePricing();
                  setSomeThingWentWrng(false);
                }}
                  style={
                    { marginLeft: '10%' }
                  }
                  color="primary"
                  variant="contained">
                  YES
                </RACButton>

              </>
              :
              <RACButton
                onClick={() => {
                  throwvalidation && managePricing();
                  setSomeThingWentWrng(false);
                }}
                color="primary"
                variant="contained"
              >
                OK
              </RACButton>}
          </Grid>
        </Grid>
      </>
    );
  };
  const scrollToTop = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  const removePricingPopupFn = () => (
    <>
      <Grid
        style={{
          justifyContent: "center",
          display: "flex",
        }}
      >
        <SuccessIcon width={"10%"} height={"10%"} />
      </Grid>
      <Grid>
        <Typography
          variant="h5"
          style={{
            textAlign: "center",
            padding: '2% 0%'
          }}
        >
          {'Are you sure you want to remove pricing?'}
        </Typography>
      </Grid>
      <Grid
        style={{
          justifyContent: "center",
          display: "flex",
          marginTop: '5%'
        }}
      >
        <>
          <RACButton onClick={() => {
              setShowPopup({ ...showPopup, ['removePricing']: false })
          }}
            color="primary"
            variant="outlined">
            No
          </RACButton>
          <RACButton onClick={() => {
            removePricingFn()
            setShowPopup({ ...showPopup, ['removePricing']: false })
          }}
            style={
              { marginLeft: '10%' }
            }
            color="primary"
            variant="contained">
            Yes
          </RACButton>

        </>
      </Grid>

    </>
  )

  const ValidationComp = (props: FormValidationObject) => {
    const { isOpen, message, isError } = props
    const color = isError ? 'rgb(255,0,0,0.10)' : 'rgb(13,110,253,0.10)'
    return (<>
      {isOpen ? <div style={{ border: `2px solid ${color}`, backgroundColor: `${color}`, paddingLeft: '10px', borderRadius: '10px' }} ref={formRef as React.RefObject<HTMLDivElement | HTMLDivElement>}>
        <h5 style={{ color: `${isError ? 'rgb(255,0,0)' : 'rgb(13,110,253)'}` }}>{message}</h5>
      </div> : <></>}
    </>)
  }
  const removePricingFn = async () => {
    extractPricingInfo(selectedItemsArray).then((item: any) => {
      managePricing(item);
    });
  }
  const extractPricingInfo = async (data: any) => {
    const removepricingParmaters = await data.map((item: any) => ({
      startDate: moment(item.startDate).format('YYYY-MM-DD hh:mm:ss'),
      endDate: moment(item.endDate).format('YYYY-MM-DD hh:mm:ss'),
      pricingParamValueIds: item.pricingParamValueIds.map((item: string)=> Number(item)),
    }));
    setAddPricingReq({ ...addPricingReq, queueType: 'IMMEDIATE', removePricingDetails: [...removepricingParmaters] });
    return removepricingParmaters;
  };

  return (
    <>
      <Card
        style={{
          position: "fixed",
          bottom: "0px",
          width: "100%",
          zIndex: "1",
          left: "0",
        }}
      >
        <CardContent style={{ padding: '1% important', float: 'right', width: '100%' }}>
          <Grid container style={{ float: "right", width: '100%', display: 'inline' }}
            spacing={3}  >
            <RACButton
              variant="contained"
              disabled={selectedItemsArray.length ? false : true}
              color="primary"
              size="small"
              style={{ float: "right", marginRight: "1%" }}
              onClick={() => {
                setThrowValidation('');
                {
                  if (
                    selectedItemsArray.every((item: any) => {
                      return (
                        item.pricingType == selectedItemsArray[0].pricingType
                      );
                    })
                  ) {
                    setIsUpdatePricingOpen(true);
                    setIsNewPricingOpen(true);
                  } else {
                    setIsUpdateWarningOpen(
                      true
                    ); /* Needed to include the warning popup set state */
                  }
                }
              }}
            >
              Update
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              size="small"
              style={{ float: "right", marginRight: "1%" }}
              onClick={() => {
                setThrowValidation('');
                setAddPricingReq(addPricingRequestOject);
                setZoneSelected([]);
                setIsNewPricingOpen(!isNewPricingOpen);
              }}
            >
              New Pricing
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              size="small"
              style={{ float: "right", marginRight: "1%", backgroundColor: "#E85C5C" ,opacity:`${selectedItemsArray.length == 0 ? 0.5 : 1}`}}
              onClick={() => {
                if(selectedItemsArray.some((item: any)=> (item.pricingType != 'TEMPORARY'))){
                  setIsUpdateWarningOpen(true);
                  setShowPopup({ ...showPopup, ['cautionRemovePricing']: true,['removePricing']: false })
                }else
                  setShowPopup({ ...showPopup, ['removePricing']: true })
              }}
              disabled= {selectedItemsArray.length == 0}
            >
              Remove Pricing
            </RACButton>
          </Grid>
          <Grid style={{ float: "right" }}></Grid>
        </CardContent>
      </Card>
      <Grid className={classes1.attachmentsPopUpStyles}>
        <RACModalCard
          isOpen={isAttachmentOpen >= 0 ? true : false}
          maxWidth="lg"
          borderRadius={10}
          onClose={() => {
            setIsAttachmentOpen(undefined);
          }}
          closeIcon={true}
          className={classes1.attachmentImageStyle}
          title="Product Image"
        >
          {attachmentsPopUp()}
        </RACModalCard>
      </Grid>
      <RACModalCard
        borderRadius={10}
        isOpen={isNewPricingOpen}
        maxWidth="md"
        onClose={() => {
          setAddPricingReq(JSON.parse(JSON.stringify(formBackupData)));
          setIsNewPricingOpen(!isNewPricingOpen);
          setFormValidationObject({ ...formValidationObject, isOpen: false, message: '', isError: false })
          setIsUpdatePricingOpen(false);
          sethierarchyLevelSelected(hierarchyLevelObj)
        }}
        closeIcon={true}
        className={classes1.dialogBox}
        title={isUpdatePricingOpen ? "Update Pricing" : "New Pricing"} ///Validate
      >
        {pricingPopUp()}
      </RACModalCard>
      <RACModalCard
        isOpen={isUpdateWarningOpen}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => {
          setIsUpdateWarningOpen(false);
          setShowPopup({ ...showPopup, ['cautionRemovePricing']: false,['removePricing']: false })
        }}
        borderRadius={10}
      >
        {updatingWarning()}
      </RACModalCard>
      <RACModalCard
        isOpen={isApprovalOpen}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => setIsApprovalOpen(false)}
        borderRadius={10}
      >
        {approvalPopup()}
      </RACModalCard>
      <RACModalCard
        className={classes1.customWarningPopupStyles}
        isOpen={someThingWentWrng}
        maxWidth="xs"
        borderRadius={10}
        closeIcon={throwvalidation === ''}
        onClose={() => setSomeThingWentWrng(false)}
      >
        {someThingWentWrongFn()}
      </RACModalCard>
      <RACModalCard
        isOpen={showPopup.removePricing}
        maxWidth='xs'
        borderRadius={10}
        closeIcon={true}
        onClose={() => {
          setShowPopup({ ...showPopup, ['removePricing']: false })
        }}>
        {removePricingPopupFn()}
      </RACModalCard>
    </>
  );
}
