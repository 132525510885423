/* eslint-disable */
export const ProductType = 'RPDPRC';

export enum ProductPermissions {
  PRICING = 'PRICING',
}

export const EXCEL_COLUMN_HEADER = {
  ZONE: 'Zone',
  TYPE: 'Type',
  DEPARTMENT: 'Department',
  SUB_DEPARTMENT: 'Subdepartment',
  BRACKET: 'Bracket',
  RMS_ITEM_NUMBER: 'RMS Number',
  WEEKLY_RATE_NEW: 'Weekly Rate New',
  WEEKLY_RATE_USED: 'Weekly Rate Used',
  ADDON_WEEKLY_RATE: 'Add-On Weekly Rate',
  TERM: 'Term',
  CASH_PRICE_FACTOR: 'Cash Price Factor',
  FORCED_CASH_PRICE: 'Forced Cash Price',
  MANUAL_PO_TURNS: 'Manual PO Turns',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  VALIDATION_STATUS: 'Validation Status',
  VALIDATION_COMMENTS: 'Validation Comments',
  UPLOAD_STATUS: 'Upload Status',
  UPLOAD_COMMENTS: 'Upload Comments',
  SAC_DAYS: 'SAC Days',
  SAC_DAYS_PRINTED: 'SAC Days Printed',
  CLEARANCE: 'Clearance',
  EPO_PERCENT: 'EPO Percentage',
};

export const EXCEL_COLUMN_VALUES = {
  ZONE_INDEX: 0,
  TYPE_INDEX: 1,
  DEPARTMENT_INDEX: 2,
  SUB_DEPARTMENT_INDEX: 3,
  BRACKET_INDEX: 4,
  RMS_ITEM_NUMBER_INDEX: 5,
  WEEKLY_RATE_NEW_INDEX: 6,
  WEEKLY_RATE_USED_INDEX: 7,
  ADDON_WEEKLY_RATE_INDEX: 8,
  TERM_INDEX: 9,
  CASH_PRICE_FACTOR_INDEX: 10,
  FORCED_CASH_PRICE_INDEX: 11,
  MANUALPO_TURNS_INDEX: 12,
  SAC_DAYS_INDEX: 13,
  SAC_DAYS_PRINTED_INDEX: 14,
  EPO_PERCENT_INDEX: 15,
  CLEARANCE_INDEX: 16,
  START_DATE_INDEX: 17,
  END_DATE_INDEX: 18,
  VALIDATION_STATUS_INDEX: 19,
  VALIDATION_COMMENTS_INDEX: 20,
  UPLOAD_STATUS_INDEX: 21,
  UPLOAD_COMMENTS_INDEX: 22,
};
export const VERIFY_IN_PROG = 'Verification In-progress';
export const REMOVE_PRICING = 'Remove Pricing';

export const excelColumnOrder = [
  'zoneNumber',
  'departmentName',
  'subDepartmentName',
  'bracketName',
  'rmsItemNumber',
  'rmsItemDescription',
  'pricingType',
  'weeklyRateNew',
  'weeklyRateUsed',
  'weeklyRateAddOn',
  'term',
  'cashPriceFactor',
  'forcedCashPrice',
  'manualPoTurns',
  'sacDays',
  'sacDaysPrinted',
  'epoPercent',
  'brandName',
  'modelNumber',
  'purchaseCost',
  'eaFlag',
  'clearance',
  'cashPriceNew',
  'cashPriceTurn',
  'totalCostNew',
  'totalRtoTurn',
  'cashPriceUsed',
  'totalCostUsed',
  'startDate',
  'endDate',
];

export const headerMapping: { [key: string]: string } = {
  zoneNumber: 'Zone Number',
  departmentName: 'Department Name',
  subDepartmentName: 'Sub Department Name',
  bracketName: 'Bracket Name',
  rmsItemNumber: 'RMS Item Number',
  rmsItemDescription: 'RMS Item Description',
  pricingType: 'Pricing Type',
  weeklyRateNew: 'Weekly Rate New',
  weeklyRateUsed: 'Weekly Rate Used',
  weeklyRateAddOn: 'Add-On Rate',
  term: 'Term',
  cashPriceFactor: 'Cash Price Factor',
  forcedCashPrice: 'Forced Cash Price',
  manualPoTurns: 'Manual PO-Turns',
  sacDays: 'SAC Days',
  sacDaysPrinted: 'SAC Days Printed',
  epoPercent: 'EPO Percentage',
  brandName: 'Brand',
  modelNumber: 'Model#',
  purchaseCost: 'Purchase Cost',
  eaFlag: 'EA Flag',
  clearance: 'Clearance',
  cashPriceNew: 'Cash Price (NEW)',
  cashPriceTurn: 'Cash Price Turns (NEW)',
  totalCostNew: 'Total Cost (NEW)',
  totalRtoTurn: 'TRTO Turns (NEW)',
  cashPriceUsed: 'Cash Price (USED)',
  totalCostUsed: 'Total Cost (USED)',
  startDate: 'Start Date',
  endDate: 'End Date',
};

export const columnWidths = [
  { wch: 15 }, // Zone Number
  { wch: 20 }, // Department Name
  { wch: 20 }, // Sub Department Name
  { wch: 10 }, // Bracket Name
  { wch: 15 }, // RMS Item Number
  { wch: 35 }, // RMS Item Description
  { wch: 15 }, // Pricing Type
  { wch: 15 }, // Weekly Rate New
  { wch: 15 }, // Weekly Rate Used
  { wch: 15 }, // Add-On Rate
  { wch: 10 }, // Term
  { wch: 15 }, // Cash Price Factor
  { wch: 15 }, // Forced Cash Price
  { wch: 15 }, // Manual PO-Turns
  { wch: 15 }, // Purchase Cost
  { wch: 15 }, // Brand
  { wch: 15 }, // Model#
  { wch: 10 }, // ea Flag
  { wch: 15 }, // Cash Price (NEW)
  { wch: 20 }, // Cash Price Turns (NEW)
  { wch: 15 }, // Total Cost (NEW)
  { wch: 15 }, // TRTO Turns (NEW)
  { wch: 15 }, // Cash Price (USED)
  { wch: 15 }, // Total Cost (USED)
  { wch: 15 }, // Start Date
  { wch: 15 }, // End Date
];

export const VERIFYPRC = 'VERIFYPRC';

export const EXCEL_HEADER_SC_INDEX = {
  ZONE_IND: 0,
  DEPARTMENT_IND: 1,
  SUB_DEPARTMENT_IND: 2,
  BRACKET_IND: 3,
  BRAND_IND: 4,
  RMS_IND: 5,
  RULE_TYPE_IND: 6,
  RULE_APP_ORDER_IND: 7,
  CRITERIA_TYPE_IND: 8,
  PRICE_PARAM_IND: 9,
  EFFECT_TYPE_IND: 10,
  START_RANGE_IND: 11,
  END_RANGE_IND: 12,
  CATEGORY_TYPE_IND: 13,
  CATEGORY_SUBTYPE_IND: 14,
  EQUATION_TYPE_IND: 15,
  EQUATION_PARAM_IND: 16,
  DISCOUNT_VALUE_IND: 17,
  START_DATE_IND: 18,
  VALIDATION_STATUS_IND: 19,
  VALIDATION_COMMENT_IND: 20,
  UPLOAD_STATUS_IND: 21,
  UPLAOD_COMMENTS_IND: 22,
};
export const CRITERIA_HEADERS = {
  ZONE_SC: 'Zone',
  DEPARTMENT_SC: 'Department',
  SUBDEPARTMENT_SC: 'Subdepartment',
  BRACKET_SC: 'Bracket',
  BRAND_SC: 'Brand',
  RMS_NUMBER_SC: 'RMS Number',
  RULE_TYPE: 'Rule Type',
  RULE_APPLICATION_ORDER: 'Rule Application Order',
  SECONDARY_CRITERIA_TYPE: 'Secondary Criteria Type',
  PRICE_PARAMETER: 'Price Parameter',
  CRITERIA_EFFECT_TYPE: 'Criteria Effect Type',
  START_RANGE: 'Start Range',
  END_RANGE: 'End Range',
  CATEGORY_TYPE: 'Category Type',
  CATEGORY_SUBTYPE: 'Category Subtype',
  EQUATION_TYPE: 'Equation Type',
  EQUATION_KEY: 'Equation Key',
  DISCOUNT_VALUE: 'Discount Value',
  START_DATE_SC: 'Start Date',
  VALIDATION_STATUS_SC: 'Validation Status',
  VALIDATION_COMMENTS_SC: 'Validation Comments',
  UPLOAD_STATUS_SC: 'Upload Status',
  UPLOAD_COMMENTS_SC: 'Upload Comments',
};
export const SECONDARY_CRITERIA = 'Secondary Criteria';

export const CRITERIA_EFFECTIVE_TYPE =['NUMBER','PERCENTAGE']
