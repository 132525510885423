import React from 'react';
import { ReactComponent as Days } from '../../assets/images/calendar.svg';
import { ReactComponent as Int } from '../../assets/images/numbers.svg';
import { ReactComponent as Dollar } from '../../assets/images/dollar.svg';
import { ReactComponent as Term } from '../../assets/images/terms.svg';
import { ReactComponent as Text } from '../../assets/images/string.svg';
import { ReactComponent as Percentage } from '../../assets/images/percentage.svg';

const getBusinessValueIcon = (datatype?: string) => {
  switch (datatype?.toUpperCase()) {
    case 'STRING':
      return <Text />;
    case 'PERCENTAGE':
      return <Percentage />;
    case 'AMOUNT':
      return <Dollar />;
    case 'NUMBER':
      return <Int />;
    case 'TERM':
      return <Term />;
    case 'DAYS':
      return <Days />;
    default:
      return <Text />;
  }
};
export { getBusinessValueIcon };
